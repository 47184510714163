export const dailyWaffles = [
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "S", currCoord: "20", color: "#323434" },
      { id: "03", letter: "K", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "B", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "42", color: "#323434" },
      { id: "24", letter: "I", currCoord: "02", color: "#323434" },
      { id: "30", letter: "Y", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "G", currCoord: "12", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "S", currCoord: "20", color: "#323434" },
      { id: "03", letter: "K", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "B", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "42", color: "#323434" },
      { id: "24", letter: "I", currCoord: "02", color: "#323434" },
      { id: "30", letter: "Y", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "G", currCoord: "12", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CASKS",
      row2: "ABACI",
      row4: "SIEGE",
      col0: "CLAYS",
      col2: "STALE",
      col4: "SNIPE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "G", currCoord: "01", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "10", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "43", color: "#323434" },
      { id: "30", letter: "G", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "N", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "G", currCoord: "01", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "10", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "43", color: "#323434" },
      { id: "30", letter: "G", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "N", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BINGO",
      row2: "LOCAL",
      row4: "EVENS",
      col0: "BILGE",
      col2: "NICHE",
      col4: "OGLES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "T", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "B", currCoord: "42", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "24", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "C", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "T", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "B", currCoord: "42", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "24", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "C", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "POETS",
      row2: "ABACA",
      row4: "HOTLY",
      col0: "PEACH",
      col2: "ENACT",
      col4: "SHAKY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "W", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "32", color: "#323434" },
      { id: "21", letter: "M", currCoord: "23", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "10", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "W", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "32", color: "#323434" },
      { id: "21", letter: "M", currCoord: "23", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "10", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SHREW",
      row2: "AMBLE",
      row4: "KITTY",
      col0: "SLACK",
      col2: "ROBOT",
      col4: "WEEPY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "G", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "24", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "D", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "G", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "24", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "D", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DRUGS",
      row2: "CEDAR",
      row4: "YARDS",
      col0: "DICEY",
      col2: "UDDER",
      col4: "SURAS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "34", color: "#323434" },
      { id: "02", letter: "B", currCoord: "14", color: "#323434" },
      { id: "03", letter: "O", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "T", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "I", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "12", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "03", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "34", color: "#323434" },
      { id: "02", letter: "B", currCoord: "14", color: "#323434" },
      { id: "03", letter: "O", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "T", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "I", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "12", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "03", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ARBOR",
      row2: "TAILS",
      row4: "RADON",
      col0: "ASTIR",
      col2: "BLIND",
      col4: "RESIN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "42", color: "#323434" },
      { id: "03", letter: "T", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "30", color: "#323434" },
      { id: "24", letter: "O", currCoord: "14", color: "#323434" },
      { id: "30", letter: "G", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "42", color: "#323434" },
      { id: "03", letter: "T", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "30", color: "#323434" },
      { id: "24", letter: "O", currCoord: "14", color: "#323434" },
      { id: "30", letter: "G", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MORTS",
      row2: "RONDO",
      row4: "ESSAY",
      col0: "MARGE",
      col2: "RUNGS",
      col4: "SOOTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "23", color: "#323434" },
      { id: "02", letter: "D", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "V", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "23", color: "#323434" },
      { id: "02", letter: "D", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "V", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "REDID",
      row2: "VENUE",
      row4: "DARKS",
      col0: "RAVED",
      col2: "DONOR",
      col4: "DEEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "C", currCoord: "10", color: "#323434" },
      { id: "03", letter: "O", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "41", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "32", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "M", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "C", currCoord: "10", color: "#323434" },
      { id: "03", letter: "O", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "41", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "32", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "M", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TACOS",
      row2: "OZONE",
      row4: "PESTS",
      col0: "TROMP",
      col2: "CLOYS",
      col4: "SKEWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "E", currCoord: "03", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "32", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "42", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "E", currCoord: "03", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "32", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "42", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DOLES",
      row2: "RETRO",
      row4: "DURST",
      col0: "DARED",
      col2: "LITER",
      col4: "STOAT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "V", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "M", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "B", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "B", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "V", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "M", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "B", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "B", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "JIVED",
      row2: "MARGE",
      row4: "OBEYS",
      col0: "JUMBO",
      col2: "VERVE",
      col4: "DEEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "M", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "01", color: "#323434" },
      { id: "20", letter: "V", currCoord: "34", color: "#323434" },
      { id: "21", letter: "I", currCoord: "23", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "M", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "01", color: "#323434" },
      { id: "20", letter: "V", currCoord: "34", color: "#323434" },
      { id: "21", letter: "I", currCoord: "23", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WARMS",
      row2: "VIDEO",
      row4: "SIREN",
      col0: "WAVES",
      col2: "RUDER",
      col4: "SWOON",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Z", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "42", color: "#323434" },
      { id: "20", letter: "W", currCoord: "12", color: "#323434" },
      { id: "21", letter: "H", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "30", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "L", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "N", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Z", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "42", color: "#323434" },
      { id: "20", letter: "W", currCoord: "12", color: "#323434" },
      { id: "21", letter: "H", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "30", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "L", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "N", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CHOIR",
      row2: "WHORL",
      row4: "SPEND",
      col0: "COWLS",
      col2: "OZONE",
      col4: "RULED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "Q", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "R", currCoord: "20", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "23", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "41", color: "#323434" },
      { id: "24", letter: "F", currCoord: "02", color: "#323434" },
      { id: "30", letter: "I", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "Q", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "R", currCoord: "20", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "23", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "41", color: "#323434" },
      { id: "24", letter: "F", currCoord: "02", color: "#323434" },
      { id: "30", letter: "I", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "QUIRT",
      row2: "ALOOF",
      row4: "SASSY",
      col0: "QUAIS",
      col2: "IDOLS",
      col4: "TAFFY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "P", currCoord: "42", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "30", color: "#323434" },
      { id: "20", letter: "M", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "43", color: "#323434" },
      { id: "22", letter: "V", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "20", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "P", currCoord: "42", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "30", color: "#323434" },
      { id: "20", letter: "M", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "43", color: "#323434" },
      { id: "22", letter: "V", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "20", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ROPED",
      row2: "MOVER",
      row4: "RITES",
      col0: "RUMOR",
      col2: "PIVOT",
      col4: "DIRKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "24", color: "#323434" },
      { id: "03", letter: "H", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "34", color: "#323434" },
      { id: "20", letter: "E", currCoord: "21", color: "#323434" },
      { id: "21", letter: "D", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "43", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "24", color: "#323434" },
      { id: "03", letter: "H", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "34", color: "#323434" },
      { id: "20", letter: "E", currCoord: "21", color: "#323434" },
      { id: "21", letter: "D", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "43", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SIGHS",
      row2: "EDEMA",
      row4: "PESOS",
      col0: "STEEP",
      col2: "GUESS",
      col4: "SPARS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "43", color: "#323434" },
      { id: "02", letter: "G", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "43", color: "#323434" },
      { id: "02", letter: "G", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SIGNS",
      row2: "TALON",
      row4: "DOSED",
      col0: "SATED",
      col2: "GALLS",
      col4: "SYNOD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "K", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "I", currCoord: "23", color: "#323434" },
      { id: "21", letter: "C", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "N", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "G", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "K", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "I", currCoord: "23", color: "#323434" },
      { id: "21", letter: "C", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "N", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "G", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SCAMP",
      row2: "ICIER",
      row4: "SAGES",
      col0: "SKINS",
      col2: "ACING",
      col4: "PORTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "L", currCoord: "20", color: "#323434" },
      { id: "30", letter: "S", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "02", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "L", currCoord: "20", color: "#323434" },
      { id: "30", letter: "S", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "02", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GILLS",
      row2: "ANNUL",
      row4: "PARER",
      col0: "GRASP",
      col2: "LINER",
      col4: "SOLAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "B", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "43", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "10", color: "#323434" },
      { id: "20", letter: "N", currCoord: "02", color: "#323434" },
      { id: "21", letter: "U", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "01", color: "#323434" },
      { id: "24", letter: "S", currCoord: "14", color: "#323434" },
      { id: "30", letter: "K", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "B", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "43", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "10", color: "#323434" },
      { id: "20", letter: "N", currCoord: "02", color: "#323434" },
      { id: "21", letter: "U", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "01", color: "#323434" },
      { id: "24", letter: "S", currCoord: "14", color: "#323434" },
      { id: "30", letter: "K", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FABLE",
      row2: "NULLS",
      row4: "SIEGE",
      col0: "FINKS",
      col2: "BELIE",
      col4: "ENSUE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "30", color: "#323434" },
      { id: "03", letter: "G", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "S", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "34", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "01", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "D", currCoord: "14", color: "#323434" },
      { id: "43", letter: "U", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "30", color: "#323434" },
      { id: "03", letter: "G", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "S", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "34", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "01", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "D", currCoord: "14", color: "#323434" },
      { id: "43", letter: "U", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BAGGY",
      row2: "SOBER",
      row4: "MODUS",
      col0: "BOSOM",
      col2: "GIBED",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "02", letter: "R", currCoord: "20", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "21", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "02", letter: "R", currCoord: "20", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "21", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "EYRIE",
      row2: "TASTE",
      row4: "RESET",
      col0: "EATER",
      col2: "RUSTS",
      col4: "EXERT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "I", currCoord: "10", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "43", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "U", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "J", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "K", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "L", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "I", currCoord: "10", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "43", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "U", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "J", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "K", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "L", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BAIZE",
      row2: "OUIJA",
      row4: "SIDLE",
      col0: "BOOKS",
      col2: "IVIED",
      col4: "EVADE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "30", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "W", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "L", currCoord: "42", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "24", color: "#323434" },
      { id: "24", letter: "K", currCoord: "20", color: "#323434" },
      { id: "30", letter: "C", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "30", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "W", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "L", currCoord: "42", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "24", color: "#323434" },
      { id: "24", letter: "K", currCoord: "20", color: "#323434" },
      { id: "30", letter: "C", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SNOWY",
      row2: "ALACK",
      row4: "KEYED",
      col0: "SLACK",
      col2: "OVARY",
      col4: "YOKED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "34", color: "#323434" },
      { id: "03", letter: "N", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "U", currCoord: "14", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "D", currCoord: "23", color: "#323434" },
      { id: "30", letter: "F", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "30", color: "#323434" },
      { id: "40", letter: "F", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "34", color: "#323434" },
      { id: "03", letter: "N", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "U", currCoord: "14", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "D", currCoord: "23", color: "#323434" },
      { id: "30", letter: "F", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "30", color: "#323434" },
      { id: "40", letter: "F", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FROND",
      row2: "UNDID",
      row4: "FLEET",
      col0: "FLUFF",
      col2: "OLDIE",
      col4: "DIDST",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "32", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "24", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "32", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "24", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FIERY",
      row2: "ELATE",
      row4: "SATED",
      col0: "FREES",
      col2: "EXACT",
      col4: "YIELD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "12", color: "#323434" },
      { id: "03", letter: "D", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "10", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "02", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "12", color: "#323434" },
      { id: "03", letter: "D", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "10", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "02", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MINDS",
      row2: "RAWER",
      row4: "SPLAT",
      col0: "MORNS",
      col2: "NEWEL",
      col4: "STRUT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "X", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "32", color: "#323434" },
      { id: "20", letter: "C", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "T", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "N", currCoord: "24", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "20", color: "#323434" },
      { id: "02", letter: "X", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "32", color: "#323434" },
      { id: "20", letter: "C", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "T", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "43", color: "#323434" },
      { id: "42", letter: "N", currCoord: "24", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FIXIT",
      row2: "CANOE",
      row4: "SENSE",
      col0: "FACTS",
      col2: "XENON",
      col4: "THESE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "14", color: "#323434" },
      { id: "21", letter: "B", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "43", color: "#323434" },
      { id: "24", letter: "I", currCoord: "32", color: "#323434" },
      { id: "30", letter: "C", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "12", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "42", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "14", color: "#323434" },
      { id: "21", letter: "B", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "43", color: "#323434" },
      { id: "24", letter: "I", currCoord: "32", color: "#323434" },
      { id: "30", letter: "C", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "12", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "42", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SOLID",
      row2: "ABACI",
      row4: "KITTY",
      col0: "SLACK",
      col2: "LEAPT",
      col4: "DAISY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "42", color: "#323434" },
      { id: "20", letter: "T", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "H", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "42", color: "#323434" },
      { id: "20", letter: "T", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "H", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TOONS",
      row2: "TEASE",
      row4: "ESSES",
      col0: "TITHE",
      col2: "OPALS",
      col4: "SPECS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "24", color: "#323434" },
      { id: "03", letter: "R", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "W", currCoord: "21", color: "#323434" },
      { id: "21", letter: "H", currCoord: "34", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "10", color: "#323434" },
      { id: "24", letter: "P", currCoord: "20", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "24", color: "#323434" },
      { id: "03", letter: "R", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "W", currCoord: "21", color: "#323434" },
      { id: "21", letter: "H", currCoord: "34", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "10", color: "#323434" },
      { id: "24", letter: "P", currCoord: "20", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FIERY",
      row2: "WHELP",
      row4: "SITES",
      col0: "FOWLS",
      col2: "EXERT",
      col4: "YIPES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "24", color: "#323434" },
      { id: "02", letter: "P", currCoord: "42", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "F", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "F", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "41", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "03", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "N", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "24", color: "#323434" },
      { id: "02", letter: "P", currCoord: "42", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "F", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "F", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "41", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "03", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "N", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BIPED",
      row2: "FAKIR",
      row4: "AVANT",
      col0: "BUFFA",
      col2: "PUKKA",
      col4: "DURST",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "20", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "N", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "42", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "20", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "N", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "42", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BENDS",
      row2: "INTRO",
      row4: "SALTS",
      col0: "BRIES",
      col2: "NATAL",
      col4: "SCOWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "C", currCoord: "23", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "10", color: "#323434" },
      { id: "21", letter: "X", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "D", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "C", currCoord: "23", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "10", color: "#323434" },
      { id: "21", letter: "X", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "D", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FACTO",
      row2: "EXTRA",
      row4: "SURDS",
      col0: "FEEDS",
      col2: "CATER",
      col4: "ORALS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "30", color: "#323434" },
      { id: "02", letter: "I", currCoord: "21", color: "#323434" },
      { id: "03", letter: "C", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "12", color: "#323434" },
      { id: "20", letter: "D", currCoord: "14", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "02", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "R", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "30", color: "#323434" },
      { id: "02", letter: "I", currCoord: "21", color: "#323434" },
      { id: "03", letter: "C", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "12", color: "#323434" },
      { id: "20", letter: "D", currCoord: "14", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "02", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "R", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "EPICS",
      row2: "DRIER",
      row4: "WORRY",
      col0: "ENDOW",
      col2: "ICIER",
      col4: "STRAY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "S", currCoord: "34", color: "#323434" },
      { id: "21", letter: "T", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "10", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "S", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "N", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "S", currCoord: "34", color: "#323434" },
      { id: "21", letter: "T", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "10", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "S", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "N", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RALLY",
      row2: "STOMP",
      row4: "EVENS",
      col0: "RUSSE",
      col2: "LOOSE",
      col4: "YIPES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "03", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "41", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "T", currCoord: "10", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "P", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "23", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "R", currCoord: "21", color: "#323434" },
      { id: "43", letter: "P", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "03", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "41", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "T", currCoord: "10", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "P", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "23", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "R", currCoord: "21", color: "#323434" },
      { id: "43", letter: "P", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ACORN",
      row2: "ETHER",
      row4: "HARPY",
      col0: "ALEPH",
      col2: "OTHER",
      col4: "NERVY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "H", currCoord: "12", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "41", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "T", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "H", currCoord: "12", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "41", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "T", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GAYLY",
      row2: "THWAP",
      row4: "APSES",
      col0: "GOTTA",
      col2: "YAWNS",
      col4: "YIPES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "30", color: "#323434" },
      { id: "02", letter: "O", currCoord: "23", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "X", currCoord: "21", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "43", letter: "L", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "30", color: "#323434" },
      { id: "02", letter: "O", currCoord: "23", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "X", currCoord: "21", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "43", letter: "L", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SPOKE",
      row2: "RADIO",
      row4: "WRYLY",
      col0: "SCREW",
      col2: "ODDLY",
      col4: "EPOXY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "32", color: "#323434" },
      { id: "02", letter: "K", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "P", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "43", color: "#323434" },
      { id: "42", letter: "L", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "32", color: "#323434" },
      { id: "02", letter: "K", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "P", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "43", color: "#323434" },
      { id: "42", letter: "L", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "COKES",
      row2: "LAUDE",
      row4: "ATLAS",
      col0: "CULPA",
      col2: "KNURL",
      col4: "SKEWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "R", currCoord: "41", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "01", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "I", currCoord: "42", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "10", color: "#323434" },
      { id: "24", letter: "O", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "34", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "R", currCoord: "41", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "01", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "I", currCoord: "42", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "10", color: "#323434" },
      { id: "24", letter: "O", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "34", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WORMS",
      row2: "LIMBO",
      row4: "DRAYS",
      col0: "WALED",
      col2: "RUMBA",
      col4: "SLOPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "23", color: "#323434" },
      { id: "03", letter: "R", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "21", color: "#323434" },
      { id: "21", letter: "P", currCoord: "32", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "24", color: "#323434" },
      { id: "24", letter: "M", currCoord: "41", color: "#323434" },
      { id: "30", letter: "R", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "02", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "12", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "23", color: "#323434" },
      { id: "03", letter: "R", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "21", color: "#323434" },
      { id: "21", letter: "P", currCoord: "32", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "24", color: "#323434" },
      { id: "24", letter: "M", currCoord: "41", color: "#323434" },
      { id: "30", letter: "R", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "02", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "12", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SCARY",
      row2: "OPIUM",
      row4: "ESSAY",
      col0: "SCORE",
      col2: "ALIAS",
      col4: "YUMMY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "P", currCoord: "20", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "R", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "D", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "03", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "P", currCoord: "20", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "R", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "D", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "03", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TAPER",
      row2: "RILED",
      row4: "SUDSY",
      col0: "TIRES",
      col2: "POLED",
      col4: "REDLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "42", color: "#323434" },
      { id: "02", letter: "E", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "W", currCoord: "10", color: "#323434" },
      { id: "21", letter: "H", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "L", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "14", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "34", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "O", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "42", color: "#323434" },
      { id: "02", letter: "E", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "W", currCoord: "10", color: "#323434" },
      { id: "21", letter: "H", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "L", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "14", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "34", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "O", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NEEDS",
      row2: "WHORL",
      row4: "YOYOS",
      col0: "NEWLY",
      col2: "EBONY",
      col4: "SULKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "N", currCoord: "10", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "R", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "N", currCoord: "10", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "R", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BRENT",
      row2: "RAISE",
      row4: "SITES",
      col0: "BARNS",
      col2: "EDICT",
      col4: "TIERS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "G", currCoord: "02", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "24", color: "#323434" },
      { id: "20", letter: "G", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "12", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "03", color: "#323434" },
      { id: "24", letter: "L", currCoord: "01", color: "#323434" },
      { id: "30", letter: "A", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "G", currCoord: "02", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "24", color: "#323434" },
      { id: "20", letter: "G", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "12", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "03", color: "#323434" },
      { id: "24", letter: "L", currCoord: "01", color: "#323434" },
      { id: "30", letter: "A", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BINGO",
      row2: "GRUEL",
      row4: "NOSED",
      col0: "BEGAN",
      col2: "NOUNS",
      col4: "OILED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "S", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "E", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "30", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "D", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "W", currCoord: "21", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "S", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "E", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "30", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "D", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "W", currCoord: "21", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NOSEY",
      row2: "EARNS",
      row4: "YOWLS",
      col0: "NEEDY",
      col2: "STREW",
      col4: "YESES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SIRED",
      row2: "DONNA",
      row4: "RESTS",
      col0: "SEDER",
      col2: "RUNGS",
      col4: "DRAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "41", color: "#323434" },
      { id: "02", letter: "A", currCoord: "24", color: "#323434" },
      { id: "03", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "K", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "30", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "20", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "O", currCoord: "32", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "41", color: "#323434" },
      { id: "02", letter: "A", currCoord: "24", color: "#323434" },
      { id: "03", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "K", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "30", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "20", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "O", currCoord: "32", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SLAYS",
      row2: "INTRA",
      row4: "TAROT",
      col0: "SKIRT",
      col2: "ALTAR",
      col4: "START",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "34", color: "#323434" },
      { id: "24", letter: "S", currCoord: "32", color: "#323434" },
      { id: "30", letter: "M", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "D", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "34", color: "#323434" },
      { id: "24", letter: "S", currCoord: "32", color: "#323434" },
      { id: "30", letter: "M", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "D", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "INLAY",
      row2: "EARLS",
      row4: "SIDES",
      col0: "ITEMS",
      col2: "LURED",
      col4: "YESES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "43", color: "#323434" },
      { id: "02", letter: "O", currCoord: "42", color: "#323434" },
      { id: "03", letter: "U", currCoord: "23", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "03", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "41", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "43", color: "#323434" },
      { id: "02", letter: "O", currCoord: "42", color: "#323434" },
      { id: "03", letter: "U", currCoord: "23", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "03", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "41", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CLOUT",
      row2: "ROLLS",
      row4: "YARDS",
      col0: "CURLY",
      col2: "OGLER",
      col4: "TUSKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "U", currCoord: "43", color: "#323434" },
      { id: "21", letter: "N", currCoord: "03", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "32", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "U", currCoord: "43", color: "#323434" },
      { id: "21", letter: "N", currCoord: "03", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "32", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CARED",
      row2: "UNMET",
      row4: "ENTRY",
      col0: "CHUTE",
      col2: "REMIT",
      col4: "DOTTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "O", currCoord: "42", color: "#323434" },
      { id: "03", letter: "K", currCoord: "41", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "21", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "N", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "O", currCoord: "42", color: "#323434" },
      { id: "03", letter: "K", currCoord: "41", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "21", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "01", color: "#323434" },
      { id: "43", letter: "N", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "KOOKY",
      row2: "ALTAR",
      row4: "KERNS",
      col0: "KNACK",
      col2: "OTTER",
      col4: "YARNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "K", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "32", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "T", currCoord: "02", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "20", color: "#323434" },
      { id: "30", letter: "T", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "03", color: "#323434" },
      { id: "42", letter: "R", currCoord: "14", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "K", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "32", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "T", currCoord: "02", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "20", color: "#323434" },
      { id: "30", letter: "T", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "03", color: "#323434" },
      { id: "42", letter: "R", currCoord: "14", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SKULK",
      row2: "OTHER",
      row4: "STRUT",
      col0: "SHOTS",
      col2: "USHER",
      col4: "KARAT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "V", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "43", color: "#323434" },
      { id: "20", letter: "C", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "02", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "D", currCoord: "20", color: "#323434" },
      { id: "30", letter: "U", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "32", color: "#323434" },
      { id: "42", letter: "L", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "V", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "43", color: "#323434" },
      { id: "20", letter: "C", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "02", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "D", currCoord: "20", color: "#323434" },
      { id: "30", letter: "U", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "32", color: "#323434" },
      { id: "42", letter: "L", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RAVED",
      row2: "CAGED",
      row4: "TILLS",
      col0: "RECUT",
      col2: "VIGIL",
      col4: "DUDES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "G", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "02", color: "#323434" },
      { id: "20", letter: "P", currCoord: "21", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "I", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "T", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "G", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "02", color: "#323434" },
      { id: "20", letter: "P", currCoord: "21", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "I", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "T", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ROGER",
      row2: "PRINT",
      row4: "DIETS",
      col0: "RAPID",
      col2: "GUILE",
      col4: "RITES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "M", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "02", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "41", color: "#323434" },
      { id: "24", letter: "P", currCoord: "43", color: "#323434" },
      { id: "30", letter: "C", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "M", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "02", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "41", color: "#323434" },
      { id: "24", letter: "P", currCoord: "43", color: "#323434" },
      { id: "30", letter: "C", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NAMER",
      row2: "TULIP",
      row4: "HOSED",
      col0: "NATCH",
      col2: "MELTS",
      col4: "RAPED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "N", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "S", currCoord: "30", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "24", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "34", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "N", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "S", currCoord: "30", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "24", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "34", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BRANS",
      row2: "SHEEN",
      row4: "NOSEY",
      col0: "BISON",
      col2: "AMENS",
      col4: "SONNY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "M", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "B", currCoord: "43", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "03", color: "#323434" },
      { id: "24", letter: "R", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "32", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "M", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "B", currCoord: "43", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "03", color: "#323434" },
      { id: "24", letter: "R", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "32", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LIMOS",
      row2: "BALER",
      row4: "LASED",
      col0: "LIBEL",
      col2: "MOLDS",
      col4: "SHRED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "E", currCoord: "01", color: "#323434" },
      { id: "03", letter: "R", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "42", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "41", color: "#323434" },
      { id: "24", letter: "N", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "30", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "E", currCoord: "01", color: "#323434" },
      { id: "03", letter: "R", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "42", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "41", color: "#323434" },
      { id: "24", letter: "N", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "30", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BEERS",
      row2: "RERUN",
      row4: "DIETS",
      col0: "BORED",
      col2: "EYRIE",
      col4: "SENDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "20", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "R", currCoord: "30", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "12", color: "#323434" },
      { id: "24", letter: "N", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "03", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "A", currCoord: "41", color: "#323434" },
      { id: "43", letter: "D", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "20", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "R", currCoord: "30", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "12", color: "#323434" },
      { id: "24", letter: "N", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "03", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "A", currCoord: "41", color: "#323434" },
      { id: "43", letter: "D", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OVARY",
      row2: "TENON",
      row4: "DEADS",
      col0: "OPTED",
      col2: "APNEA",
      col4: "YANKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "K", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "02", color: "#323434" },
      { id: "20", letter: "P", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "Y", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "03", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "K", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "K", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "02", color: "#323434" },
      { id: "20", letter: "P", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "Y", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "03", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "K", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RAKES",
      row2: "PHYLA",
      row4: "DIKES",
      col0: "ROPED",
      col2: "KAYAK",
      col4: "SCADS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "M", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "24", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "H", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "20", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "S", currCoord: "43", color: "#323434" },
      { id: "30", letter: "I", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "M", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "24", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "H", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "20", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "S", currCoord: "43", color: "#323434" },
      { id: "30", letter: "I", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AMAZE",
      row2: "HEIRS",
      row4: "DREAD",
      col0: "APHID",
      col2: "ALIVE",
      col4: "EASED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "C", currCoord: "20", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "R", currCoord: "10", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "W", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "14", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "C", currCoord: "20", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "R", currCoord: "10", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "W", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "14", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BRICK",
      row2: "AROMA",
      row4: "LOSES",
      col0: "BRAWL",
      col2: "IRONS",
      col4: "KHANS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "H", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "B", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "W", currCoord: "14", color: "#323434" },
      { id: "24", letter: "L", currCoord: "30", color: "#323434" },
      { id: "30", letter: "I", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "32", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "H", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "B", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "W", currCoord: "14", color: "#323434" },
      { id: "24", letter: "L", currCoord: "30", color: "#323434" },
      { id: "30", letter: "I", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "32", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "REHAB",
      row2: "BRAWL",
      row4: "DADDY",
      col0: "RABID",
      col2: "HEARD",
      col4: "BELAY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "20", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "32", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "20", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "32", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BELLI",
      row2: "LEAVE",
      row4: "SITES",
      col0: "BALLS",
      col2: "LEAPT",
      col4: "ITEMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "03", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "V", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "A", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "14", color: "#323434" },
      { id: "42", letter: "O", currCoord: "41", color: "#323434" },
      { id: "43", letter: "B", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "03", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "V", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "A", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "14", color: "#323434" },
      { id: "42", letter: "O", currCoord: "41", color: "#323434" },
      { id: "43", letter: "B", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LOLLY",
      row2: "VOTER",
      row4: "SNOBS",
      col0: "LAVAS",
      col2: "LOTTO",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "10", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "03", color: "#323434" },
      { id: "20", letter: "H", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "24", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "12", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "W", currCoord: "20", color: "#323434" },
      { id: "42", letter: "N", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "L", currCoord: "10", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "03", color: "#323434" },
      { id: "20", letter: "H", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "24", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "O", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "12", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "W", currCoord: "20", color: "#323434" },
      { id: "42", letter: "N", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WILDS",
      row2: "HEDGE",
      row4: "OWNER",
      col0: "WAHOO",
      col2: "LADEN",
      col4: "SMEAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "O", currCoord: "34", color: "#323434" },
      { id: "03", letter: "S", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "03", color: "#323434" },
      { id: "20", letter: "S", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "23", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "32", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "O", currCoord: "34", color: "#323434" },
      { id: "03", letter: "S", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "03", color: "#323434" },
      { id: "20", letter: "S", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "23", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "32", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AROSE",
      row2: "SINGE",
      row4: "EGRET",
      col0: "AISLE",
      col2: "OWNER",
      col4: "EXERT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "02", color: "#323434" },
      { id: "02", letter: "W", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "N", currCoord: "34", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "30", letter: "O", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "A", currCoord: "41", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "02", color: "#323434" },
      { id: "02", letter: "W", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "N", currCoord: "34", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "30", letter: "O", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "A", currCoord: "41", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TOWEL",
      row2: "NANNY",
      row4: "NASAL",
      col0: "TENON",
      col2: "WINOS",
      col4: "LOYAL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "T", currCoord: "03", color: "#323434" },
      { id: "21", letter: "R", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "24", color: "#323434" },
      { id: "24", letter: "L", currCoord: "10", color: "#323434" },
      { id: "30", letter: "C", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "T", currCoord: "03", color: "#323434" },
      { id: "21", letter: "R", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "24", color: "#323434" },
      { id: "24", letter: "L", currCoord: "10", color: "#323434" },
      { id: "30", letter: "C", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NASTY",
      row2: "TRILL",
      row4: "HEELS",
      col0: "NATCH",
      col2: "SHINE",
      col4: "YELPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "30", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "30", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GATED",
      row2: "OAKEN",
      row4: "TASTE",
      col0: "GLOAT",
      col2: "TYKES",
      col4: "DUNCE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "U", currCoord: "32", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "12", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "R", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "42", letter: "R", currCoord: "43", color: "#323434" },
      { id: "43", letter: "I", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "U", currCoord: "32", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "12", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "R", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "42", letter: "R", currCoord: "43", color: "#323434" },
      { id: "43", letter: "I", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SILLS",
      row2: "OUTGO",
      row4: "EYRIE",
      col0: "STORE",
      col2: "LITER",
      col4: "STOVE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "D", currCoord: "03", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "41", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "C", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "T", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "23", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "O", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "D", currCoord: "03", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "41", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "C", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "T", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "23", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "O", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MADAM",
      row2: "OCTET",
      row4: "SHOOS",
      col0: "MOONS",
      col2: "DITTO",
      col4: "MOTES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "24", color: "#323434" },
      { id: "03", letter: "U", currCoord: "23", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "03", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "C", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "24", color: "#323434" },
      { id: "03", letter: "U", currCoord: "23", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "03", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "C", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RERUN",
      row2: "TINGE",
      row4: "DISCS",
      col0: "RATED",
      col2: "RINDS",
      col4: "NOELS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "S", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "34", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "42", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "S", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "34", color: "#323434" },
      { id: "24", letter: "P", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "42", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TOAST",
      row2: "TROOP",
      row4: "MUTED",
      col0: "TOTEM",
      col2: "AFOOT",
      col4: "TYPED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "23", color: "#323434" },
      { id: "03", letter: "B", currCoord: "32", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "02", color: "#323434" },
      { id: "20", letter: "T", currCoord: "12", color: "#323434" },
      { id: "21", letter: "I", currCoord: "10", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "A", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "30", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "34", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "23", color: "#323434" },
      { id: "03", letter: "B", currCoord: "32", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "02", color: "#323434" },
      { id: "20", letter: "T", currCoord: "12", color: "#323434" },
      { id: "21", letter: "I", currCoord: "10", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "A", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "30", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "34", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "T", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TURBO",
      row2: "TINGE",
      row4: "LUSTS",
      col0: "TOTAL",
      col2: "RINGS",
      col4: "OVENS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "M", currCoord: "32", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "M", currCoord: "10", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "P", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "F", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "M", currCoord: "32", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "M", currCoord: "10", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "P", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "F", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HAMMY",
      row2: "MASER",
      row4: "FANGS",
      col0: "HUMPF",
      col2: "MESON",
      col4: "YARNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "32", color: "#323434" },
      { id: "03", letter: "S", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "T", currCoord: "02", color: "#323434" },
      { id: "21", letter: "H", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "F", currCoord: "03", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "M", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "32", color: "#323434" },
      { id: "03", letter: "S", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "T", currCoord: "02", color: "#323434" },
      { id: "21", letter: "H", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "F", currCoord: "03", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "12", color: "#323434" },
      { id: "43", letter: "M", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GOEST",
      row2: "THIEF",
      row4: "POEMS",
      col0: "GETUP",
      col2: "ELIDE",
      col4: "TIFFS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "K", currCoord: "32", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "K", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "K", currCoord: "32", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "K", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HALED",
      row2: "KNAVE",
      row4: "DESKS",
      col0: "HIKED",
      col2: "LEADS",
      col4: "DUELS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "43", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "E", currCoord: "20", color: "#323434" },
      { id: "43", letter: "N", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "41", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "43", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "E", currCoord: "20", color: "#323434" },
      { id: "43", letter: "N", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FONTS",
      row2: "CABIN",
      row4: "TEENY",
      col0: "FACET",
      col2: "NOBLE",
      col4: "SUNNY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "41", color: "#323434" },
      { id: "02", letter: "O", currCoord: "21", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "D", currCoord: "03", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "K", currCoord: "10", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "01", color: "#323434" },
      { id: "24", letter: "I", currCoord: "02", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "41", color: "#323434" },
      { id: "02", letter: "O", currCoord: "21", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "D", currCoord: "03", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "K", currCoord: "10", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "01", color: "#323434" },
      { id: "24", letter: "I", currCoord: "02", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GNOME",
      row2: "OKAPI",
      row4: "POSTS",
      col0: "GROUP",
      col2: "OPALS",
      col4: "EDITS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "41", color: "#323434" },
      { id: "03", letter: "S", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "30", color: "#323434" },
      { id: "20", letter: "P", currCoord: "03", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "23", color: "#323434" },
      { id: "43", letter: "U", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "41", color: "#323434" },
      { id: "03", letter: "S", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "30", color: "#323434" },
      { id: "20", letter: "P", currCoord: "03", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "23", color: "#323434" },
      { id: "43", letter: "U", currCoord: "20", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CLOSE",
      row2: "PETER",
      row4: "DONUT",
      col0: "CAPED",
      col2: "OFTEN",
      col4: "EGRET",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "14", color: "#323434" },
      { id: "02", letter: "P", currCoord: "12", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "S", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "32", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "14", color: "#323434" },
      { id: "02", letter: "P", currCoord: "12", color: "#323434" },
      { id: "03", letter: "L", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "S", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "32", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TUPLE",
      row2: "AZURE",
      row4: "HEELS",
      col0: "TRASH",
      col2: "PAUSE",
      col4: "EXECS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "12", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "42", color: "#323434" },
      { id: "21", letter: "I", currCoord: "30", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "G", currCoord: "32", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "12", color: "#323434" },
      { id: "03", letter: "L", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "42", color: "#323434" },
      { id: "21", letter: "I", currCoord: "30", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "41", color: "#323434" },
      { id: "43", letter: "G", currCoord: "32", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FOULS",
      row2: "RIGOR",
      row4: "RIDGE",
      col0: "FUROR",
      col2: "URGED",
      col4: "SERVE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "L", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "F", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "U", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "X", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "L", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "F", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "U", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "X", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BALLY",
      row2: "AFTER",
      row4: "SAXES",
      col0: "BEAUS",
      col2: "LATEX",
      col4: "YARNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "C", currCoord: "12", color: "#323434" },
      { id: "03", letter: "H", currCoord: "20", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "C", currCoord: "12", color: "#323434" },
      { id: "03", letter: "H", currCoord: "20", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DACHA",
      row2: "ARGON",
      row4: "YESES",
      col0: "DEARY",
      col2: "CAGES",
      col4: "AUNTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "41", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "F", currCoord: "03", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "T", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "41", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "F", currCoord: "03", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "T", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CRIMP",
      row2: "OFFER",
      row4: "DIRTS",
      col0: "COOED",
      col2: "INFER",
      col4: "PURRS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "R", currCoord: "41", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "42", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "02", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "R", currCoord: "41", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "42", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "02", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DAIRY",
      row2: "COPRA",
      row4: "DOTES",
      col0: "DICED",
      col2: "INPUT",
      col4: "YEARS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "W", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "34", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "N", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "S", currCoord: "24", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "W", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "34", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "N", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "S", currCoord: "24", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GIANT",
      row2: "WISPS",
      row4: "SISSY",
      col0: "GOWNS",
      col2: "ASSES",
      col4: "TASTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "W", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "03", color: "#323434" },
      { id: "20", letter: "D", currCoord: "34", color: "#323434" },
      { id: "21", letter: "O", currCoord: "41", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "43", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "N", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "W", currCoord: "01", color: "#323434" },
      { id: "03", letter: "L", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "03", color: "#323434" },
      { id: "20", letter: "D", currCoord: "34", color: "#323434" },
      { id: "21", letter: "O", currCoord: "41", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "43", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "N", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "JOWLS",
      row2: "DOGMA",
      row4: "DUNES",
      col0: "JADED",
      col2: "WAGON",
      col4: "SPAYS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "41", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "14", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "F", currCoord: "43", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "L", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "21", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "T", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "41", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "14", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "F", currCoord: "43", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "L", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "21", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "T", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLOCK",
      row2: "OFFAL",
      row4: "DENTS",
      col0: "BOOED",
      col2: "OFFEN",
      col4: "KILNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "32", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "L", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "A", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "34", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "W", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "32", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "L", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "L", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "A", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "34", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "W", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ADULT",
      row2: "LABOR",
      row4: "SHREW",
      col0: "ATLAS",
      col2: "UMBER",
      col4: "THREW",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "03", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "14", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "34", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "12", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "03", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "14", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "34", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "12", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STOAT",
      row2: "BLOTS",
      row4: "ESSAY",
      col0: "SABLE",
      col2: "ODORS",
      col4: "TUSKY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "14", color: "#323434" },
      { id: "03", letter: "L", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "41", color: "#323434" },
      { id: "24", letter: "O", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "30", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "P", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "14", color: "#323434" },
      { id: "03", letter: "L", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "41", color: "#323434" },
      { id: "24", letter: "O", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "30", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "P", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HILLS",
      row2: "TANGO",
      row4: "HASPS",
      col0: "HITCH",
      col2: "LUNGS",
      col4: "SNOTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "A", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "10", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "30", color: "#323434" },
      { id: "20", letter: "W", currCoord: "01", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "A", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "10", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "30", color: "#323434" },
      { id: "20", letter: "W", currCoord: "01", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FRANK",
      row2: "WHEEL",
      row4: "SIDES",
      col0: "FOWLS",
      col2: "AHEAD",
      col4: "KILNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "O", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "02", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "O", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "02", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MOOED",
      row2: "SALON",
      row4: "NINES",
      col0: "MESON",
      col2: "ORLON",
      col4: "DINGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "A", currCoord: "41", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "F", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "32", color: "#323434" },
      { id: "24", letter: "N", currCoord: "01", color: "#323434" },
      { id: "30", letter: "T", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "A", currCoord: "41", color: "#323434" },
      { id: "03", letter: "M", currCoord: "24", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "F", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "32", color: "#323434" },
      { id: "24", letter: "N", currCoord: "01", color: "#323434" },
      { id: "30", letter: "T", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "REAMS",
      row2: "FELON",
      row4: "SEDER",
      col0: "RIFTS",
      col2: "AILED",
      col4: "SONAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "D", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "10", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "D", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "10", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HUNTS",
      row2: "LOBED",
      row4: "SHYLY",
      col0: "HULLS",
      col2: "NOBBY",
      col4: "SUDSY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "43", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "T", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "D", currCoord: "14", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "24", color: "#323434" },
      { id: "24", letter: "K", currCoord: "02", color: "#323434" },
      { id: "30", letter: "O", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "K", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "43", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "T", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "D", currCoord: "14", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "24", color: "#323434" },
      { id: "24", letter: "K", currCoord: "02", color: "#323434" },
      { id: "30", letter: "O", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "W", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "K", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WITTY",
      row2: "DRINK",
      row4: "WAKED",
      col0: "WIDOW",
      col2: "THINK",
      col4: "YOKED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "03", color: "#323434" },
      { id: "20", letter: "L", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "01", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "30", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "M", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "L", currCoord: "34", color: "#323434" },
      { id: "43", letter: "O", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "03", color: "#323434" },
      { id: "20", letter: "L", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "01", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "30", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "M", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "12", color: "#323434" },
      { id: "42", letter: "L", currCoord: "34", color: "#323434" },
      { id: "43", letter: "O", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BEETS",
      row2: "LLAMA",
      row4: "SILOS",
      col0: "BALMS",
      col2: "EMAIL",
      col4: "SCAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "C", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "L", currCoord: "10", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "H", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "23", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "C", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "L", currCoord: "10", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "H", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "23", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ILIAC",
      row2: "CLOVE",
      row4: "YESES",
      col0: "ITCHY",
      col2: "IRONS",
      col4: "CREWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "23", color: "#323434" },
      { id: "02", letter: "A", currCoord: "20", color: "#323434" },
      { id: "03", letter: "H", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "B", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "42", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "B", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "N", currCoord: "43", color: "#323434" },
      { id: "43", letter: "G", currCoord: "14", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "23", color: "#323434" },
      { id: "02", letter: "A", currCoord: "20", color: "#323434" },
      { id: "03", letter: "H", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "B", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "03", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "42", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "B", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "N", currCoord: "43", color: "#323434" },
      { id: "43", letter: "G", currCoord: "14", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLAHS",
      row2: "BASSO",
      row4: "SINGE",
      col0: "BIBBS",
      col2: "ARSON",
      col4: "SHOVE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "12", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "O", currCoord: "43", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "V", currCoord: "23", color: "#323434" },
      { id: "21", letter: "I", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "12", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "O", currCoord: "43", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "02", color: "#323434" },
      { id: "20", letter: "V", currCoord: "23", color: "#323434" },
      { id: "21", letter: "I", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "VENOM",
      row2: "VIOLA",
      row4: "DUSKY",
      col0: "VIVID",
      col2: "NOONS",
      col4: "MEANY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "D", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "X", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "41", color: "#323434" },
      { id: "20", letter: "M", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "34", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "D", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "X", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "41", color: "#323434" },
      { id: "20", letter: "M", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "34", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AIDED",
      row2: "MIMEO",
      row4: "NURSE",
      col0: "AXMEN",
      col2: "DEMUR",
      col4: "DRONE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "U", currCoord: "30", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "24", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "20", color: "#323434" },
      { id: "24", letter: "P", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "K", currCoord: "42", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "14", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "U", currCoord: "30", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "24", color: "#323434" },
      { id: "22", letter: "W", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "20", color: "#323434" },
      { id: "24", letter: "P", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "K", currCoord: "42", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DONUT",
      row2: "THWAP",
      row4: "SKYED",
      col0: "DATES",
      col2: "NEWLY",
      col4: "TAPED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "42", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "03", color: "#323434" },
      { id: "20", letter: "W", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "02", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "42", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "03", color: "#323434" },
      { id: "20", letter: "W", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "02", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "JOCKS",
      row2: "WORSE",
      row4: "DOYEN",
      col0: "JAWED",
      col2: "CORNY",
      col4: "STERN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "12", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "D", currCoord: "14", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "R", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "23", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "U", currCoord: "21", color: "#323434" },
      { id: "44", letter: "M", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "12", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "D", currCoord: "14", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "R", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "23", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "U", currCoord: "21", color: "#323434" },
      { id: "44", letter: "M", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SWASH",
      row2: "ADDER",
      row4: "SCRUM",
      col0: "SLABS",
      col2: "ALDER",
      col4: "HAREM",
    },
  },
  {
    waffle: [
      { id: "00", letter: "Z", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "R", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "N", currCoord: "03", color: "#323434" },
      { id: "21", letter: "E", currCoord: "14", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "01", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "Z", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "R", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "N", currCoord: "03", color: "#323434" },
      { id: "21", letter: "E", currCoord: "14", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "01", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ZEROS",
      row2: "NEEDY",
      row4: "DRYER",
      col0: "ZONED",
      col2: "REEDY",
      col4: "SAYER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "C", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Y", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "42", color: "#323434" },
      { id: "24", letter: "G", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "C", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Y", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "42", color: "#323434" },
      { id: "24", letter: "G", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ATTIC",
      row2: "TYING",
      row4: "REELS",
      col0: "ASTIR",
      col2: "TRIBE",
      col4: "CAGES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "C", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "02", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "23", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "C", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "02", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "23", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "COCCI",
      row2: "RECTO",
      row4: "DUSTY",
      col0: "CURED",
      col2: "COCKS",
      col4: "IRONY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "21", color: "#323434" },
      { id: "03", letter: "N", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "G", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "03", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "K", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "21", color: "#323434" },
      { id: "03", letter: "N", currCoord: "34", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "G", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "03", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "K", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TINNY",
      row2: "INTER",
      row4: "HUSKS",
      col0: "THIGH",
      col2: "NOTES",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "T", currCoord: "20", color: "#323434" },
      { id: "03", letter: "H", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "20", letter: "T", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "32", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "21", color: "#323434" },
      { id: "30", letter: "R", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "01", color: "#323434" },
      { id: "42", letter: "H", currCoord: "24", color: "#323434" },
      { id: "43", letter: "O", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "T", currCoord: "20", color: "#323434" },
      { id: "03", letter: "H", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "20", letter: "T", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "32", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "21", color: "#323434" },
      { id: "30", letter: "R", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "01", color: "#323434" },
      { id: "42", letter: "H", currCoord: "24", color: "#323434" },
      { id: "43", letter: "O", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MOTHS",
      row2: "TENON",
      row4: "EPHOD",
      col0: "MITRE",
      col2: "TENCH",
      col4: "SYNOD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "K", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "N", currCoord: "03", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "M", currCoord: "30", color: "#323434" },
      { id: "30", letter: "C", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "32", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "K", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "N", currCoord: "03", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "M", currCoord: "30", color: "#323434" },
      { id: "30", letter: "C", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "32", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CORKS",
      row2: "ANNUM",
      row4: "HOSES",
      col0: "COACH",
      col2: "RINGS",
      col4: "SUMPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "02", letter: "M", currCoord: "43", color: "#323434" },
      { id: "03", letter: "P", currCoord: "42", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "41", color: "#323434" },
      { id: "20", letter: "M", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "14", color: "#323434" },
      { id: "24", letter: "O", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "02", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "P", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "02", letter: "M", currCoord: "43", color: "#323434" },
      { id: "03", letter: "P", currCoord: "42", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "41", color: "#323434" },
      { id: "20", letter: "M", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "14", color: "#323434" },
      { id: "24", letter: "O", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "02", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "P", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NYMPH",
      row2: "MOLTO",
      row4: "RASPS",
      col0: "NAMER",
      col2: "MOLES",
      col4: "HOODS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "10", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "V", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "L", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "10", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "V", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "L", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WORDY",
      row2: "OVALS",
      row4: "EASES",
      col0: "WHOLE",
      col2: "REAPS",
      col4: "YESES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "U", currCoord: "42", color: "#323434" },
      { id: "21", letter: "T", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "G", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "P", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "G", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "U", currCoord: "42", color: "#323434" },
      { id: "21", letter: "T", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "G", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "P", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RAGED",
      row2: "UTTER",
      row4: "HASPS",
      col0: "ROUGH",
      col2: "GATES",
      col4: "DIRTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "S", currCoord: "10", color: "#323434" },
      { id: "03", letter: "K", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "30", color: "#323434" },
      { id: "20", letter: "V", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "34", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "A", currCoord: "32", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "S", currCoord: "10", color: "#323434" },
      { id: "03", letter: "K", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "30", color: "#323434" },
      { id: "20", letter: "V", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "34", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "A", currCoord: "32", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DUSKS",
      row2: "VOICE",
      row4: "SISAL",
      col0: "DIVAS",
      col2: "SHIMS",
      col4: "SMELL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "02", color: "#323434" },
      { id: "02", letter: "F", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "U", currCoord: "10", color: "#323434" },
      { id: "21", letter: "N", currCoord: "34", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "T", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "23", color: "#323434" },
      { id: "44", letter: "H", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "02", color: "#323434" },
      { id: "02", letter: "F", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "U", currCoord: "10", color: "#323434" },
      { id: "21", letter: "N", currCoord: "34", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "T", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "23", color: "#323434" },
      { id: "44", letter: "H", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FIFTH",
      row2: "UNMET",
      row4: "DIETH",
      col0: "FEUED",
      col2: "FEMME",
      col4: "HITCH",
    },
  },
  {
    waffle: [
      { id: "00", letter: "Y", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "P", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "R", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "02", color: "#323434" },
      { id: "24", letter: "O", currCoord: "14", color: "#323434" },
      { id: "30", letter: "D", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "Y", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "P", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "R", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "02", color: "#323434" },
      { id: "24", letter: "O", currCoord: "14", color: "#323434" },
      { id: "30", letter: "D", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "YELPS",
      row2: "RONDO",
      row4: "SIRES",
      col0: "YARDS",
      col2: "LONER",
      col4: "SNOTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "C", currCoord: "12", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "23", color: "#323434" },
      { id: "20", letter: "L", currCoord: "34", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "S", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "C", currCoord: "12", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "23", color: "#323434" },
      { id: "20", letter: "L", currCoord: "34", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "S", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LEECH",
      row2: "LAIRS",
      row4: "SITES",
      col0: "LOLLS",
      col2: "EDICT",
      col4: "HOSTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "D", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "14", color: "#323434" },
      { id: "21", letter: "D", currCoord: "43", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "R", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "I", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "01", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "D", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "I", currCoord: "14", color: "#323434" },
      { id: "21", letter: "D", currCoord: "43", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "R", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "I", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "01", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ACIDS",
      row2: "IDLER",
      row4: "INTER",
      col0: "ALIBI",
      col2: "INLET",
      col4: "SURER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "21", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "10", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "D", currCoord: "02", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "14", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "R", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "21", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "10", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "D", currCoord: "02", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "14", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "R", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STAIR",
      row2: "ENDED",
      row4: "MARRY",
      col0: "STEAM",
      col2: "ALDER",
      col4: "RUDDY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "01", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "C", currCoord: "02", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "D", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "I", currCoord: "12", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "01", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "C", currCoord: "02", color: "#323434" },
      { id: "22", letter: "H", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "41", color: "#323434" },
      { id: "30", letter: "D", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "I", currCoord: "12", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CHUMS",
      row2: "OCHRE",
      row4: "SPRIT",
      col0: "CLODS",
      col2: "USHER",
      col4: "SHEET",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "B", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "K", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "R", currCoord: "21", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "23", color: "#323434" },
      { id: "02", letter: "L", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "B", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "K", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "R", currCoord: "21", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SALLY",
      row2: "ABACA",
      row4: "STERN",
      col0: "SOAKS",
      col2: "LEAVE",
      col4: "YEARN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "W", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "I", currCoord: "24", color: "#323434" },
      { id: "21", letter: "C", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "X", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "10", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "W", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "I", currCoord: "24", color: "#323434" },
      { id: "21", letter: "C", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "X", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "10", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TROTH",
      row2: "ICIER",
      row4: "TUSKS",
      col0: "TWIXT",
      col2: "OBITS",
      col4: "HARMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "M", currCoord: "12", color: "#323434" },
      { id: "03", letter: "A", currCoord: "30", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "32", color: "#323434" },
      { id: "20", letter: "I", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "A", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "M", currCoord: "12", color: "#323434" },
      { id: "03", letter: "A", currCoord: "30", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "32", color: "#323434" },
      { id: "20", letter: "I", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "A", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WOMAN",
      row2: "IOTAS",
      row4: "SODAS",
      col0: "WEIRS",
      col2: "MUTED",
      col4: "NOSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "G", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "30", color: "#323434" },
      { id: "30", letter: "O", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "G", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "30", color: "#323434" },
      { id: "30", letter: "O", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "32", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SOOTS",
      row2: "IGLOO",
      row4: "NINES",
      col0: "SCION",
      col2: "ORLON",
      col4: "SHOWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "O", currCoord: "02", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "D", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "23", color: "#323434" },
      { id: "30", letter: "R", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "H", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "O", currCoord: "02", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "21", color: "#323434" },
      { id: "20", letter: "D", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "23", color: "#323434" },
      { id: "30", letter: "R", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "H", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "COLOR",
      row2: "DONUT",
      row4: "ESSES",
      col0: "CADRE",
      col2: "LENDS",
      col4: "RATHS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "X", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "N", currCoord: "42", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "41", color: "#323434" },
      { id: "30", letter: "T", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "K", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "X", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "N", currCoord: "42", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "41", color: "#323434" },
      { id: "30", letter: "T", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "K", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NIXED",
      row2: "ANNUM",
      row4: "OINKS",
      col0: "NEATO",
      col2: "XENON",
      col4: "DIMES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "G", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "30", color: "#323434" },
      { id: "22", letter: "V", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "S", currCoord: "02", color: "#323434" },
      { id: "30", letter: "P", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "G", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "30", color: "#323434" },
      { id: "22", letter: "V", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "S", currCoord: "02", color: "#323434" },
      { id: "30", letter: "P", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TIGER",
      row2: "MAVIS",
      row4: "SOLES",
      col0: "TAMPS",
      col2: "GAVEL",
      col4: "ROSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "R", currCoord: "43", color: "#323434" },
      { id: "03", letter: "D", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "34", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "F", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "R", currCoord: "43", color: "#323434" },
      { id: "03", letter: "D", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "34", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "F", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HERDS",
      row2: "OZONE",
      row4: "SITES",
      col0: "HOOFS",
      col2: "ROOST",
      col4: "SEEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "20", color: "#323434" },
      { id: "03", letter: "R", currCoord: "12", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "D", currCoord: "32", color: "#323434" },
      { id: "20", letter: "D", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "10", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "20", color: "#323434" },
      { id: "03", letter: "R", currCoord: "12", color: "#323434" },
      { id: "04", letter: "I", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "D", currCoord: "32", color: "#323434" },
      { id: "20", letter: "D", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "10", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HOURI",
      row2: "DRIVE",
      row4: "RENAL",
      col0: "HIDER",
      col2: "UNION",
      col4: "IDEAL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "C", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "B", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "C", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "B", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "02", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PESTS",
      row2: "ARENA",
      row4: "ESSES",
      col0: "PEACE",
      col2: "SUETS",
      col4: "SWABS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "I", currCoord: "30", color: "#323434" },
      { id: "03", letter: "V", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "E", currCoord: "12", color: "#323434" },
      { id: "21", letter: "L", currCoord: "43", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "W", currCoord: "42", color: "#323434" },
      { id: "30", letter: "B", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "32", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "N", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "I", currCoord: "30", color: "#323434" },
      { id: "03", letter: "V", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "E", currCoord: "12", color: "#323434" },
      { id: "21", letter: "L", currCoord: "43", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "W", currCoord: "42", color: "#323434" },
      { id: "30", letter: "B", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "32", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "N", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PRIVY",
      row2: "ELBOW",
      row4: "EVENS",
      col0: "PLEBE",
      col2: "IMBUE",
      col4: "YAWLS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "D", currCoord: "20", color: "#323434" },
      { id: "03", letter: "G", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "21", color: "#323434" },
      { id: "20", letter: "M", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "P", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "K", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "D", currCoord: "20", color: "#323434" },
      { id: "03", letter: "G", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "21", color: "#323434" },
      { id: "20", letter: "M", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "P", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "K", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LODGE",
      row2: "MELBA",
      row4: "HUSKS",
      col0: "LYMPH",
      col2: "DOLTS",
      col4: "EXAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "R", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "D", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "02", color: "#323434" },
      { id: "20", letter: "I", currCoord: "32", color: "#323434" },
      { id: "21", letter: "C", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "03", color: "#323434" },
      { id: "24", letter: "G", currCoord: "12", color: "#323434" },
      { id: "30", letter: "O", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "W", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "R", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "D", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "G", currCoord: "02", color: "#323434" },
      { id: "20", letter: "I", currCoord: "32", color: "#323434" },
      { id: "21", letter: "C", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "03", color: "#323434" },
      { id: "24", letter: "G", currCoord: "12", color: "#323434" },
      { id: "30", letter: "O", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "W", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "INURE",
      row2: "ICING",
      row4: "TWEED",
      col0: "IDIOT",
      col2: "UNITE",
      col4: "EGGED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "41", color: "#323434" },
      { id: "02", letter: "G", currCoord: "14", color: "#323434" },
      { id: "03", letter: "U", currCoord: "21", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "X", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "34", color: "#323434" },
      { id: "24", letter: "G", currCoord: "01", color: "#323434" },
      { id: "30", letter: "N", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "41", color: "#323434" },
      { id: "02", letter: "G", currCoord: "14", color: "#323434" },
      { id: "03", letter: "U", currCoord: "21", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "32", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "X", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "34", color: "#323434" },
      { id: "24", letter: "G", currCoord: "01", color: "#323434" },
      { id: "30", letter: "N", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TAGUA",
      row2: "AXING",
      row4: "SATYR",
      col0: "TUANS",
      col2: "GRIST",
      col4: "AUGUR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "P", currCoord: "10", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "S", currCoord: "42", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "34", color: "#323434" },
      { id: "30", letter: "D", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "N", currCoord: "41", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "P", currCoord: "10", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "S", currCoord: "42", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "34", color: "#323434" },
      { id: "30", letter: "D", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "N", currCoord: "41", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SLEPT",
      row2: "ASSET",
      row4: "EMEND",
      col0: "SHADE",
      col2: "ENSUE",
      col4: "TOTED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "34", color: "#323434" },
      { id: "03", letter: "A", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "I", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "34", color: "#323434" },
      { id: "03", letter: "A", currCoord: "43", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "I", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STRAY",
      row2: "ROUSE",
      row4: "GATED",
      col0: "SPRIG",
      col2: "ROUST",
      col4: "YIELD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "M", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "G", currCoord: "21", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "C", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "32", color: "#323434" },
      { id: "24", letter: "N", currCoord: "03", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "42", color: "#323434" },
      { id: "44", letter: "M", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "M", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "G", currCoord: "21", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "C", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "32", color: "#323434" },
      { id: "24", letter: "N", currCoord: "03", color: "#323434" },
      { id: "30", letter: "L", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "42", color: "#323434" },
      { id: "44", letter: "M", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OMEGA",
      row2: "ACORN",
      row4: "SPERM",
      col0: "ORALS",
      col2: "ERODE",
      col4: "ANNUM",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "24", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "42", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "21", color: "#323434" },
      { id: "30", letter: "U", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "02", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "U", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "24", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "42", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "21", color: "#323434" },
      { id: "30", letter: "U", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "02", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "U", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SCUSE",
      row2: "ROPES",
      row4: "MODUS",
      col0: "STRUM",
      col2: "UPPED",
      col4: "ESSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "Q", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "01", color: "#323434" },
      { id: "20", letter: "I", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "R", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "R", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "Q", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "01", color: "#323434" },
      { id: "20", letter: "I", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "R", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "R", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "QUICK",
      row2: "IRATE",
      row4: "TIERS",
      col0: "QUIRT",
      col2: "INANE",
      col4: "KNEES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "D", currCoord: "24", color: "#323434" },
      { id: "03", letter: "I", currCoord: "30", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "41", color: "#323434" },
      { id: "20", letter: "C", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "32", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "D", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "B", currCoord: "23", color: "#323434" },
      { id: "42", letter: "L", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "D", currCoord: "24", color: "#323434" },
      { id: "03", letter: "I", currCoord: "30", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "41", color: "#323434" },
      { id: "20", letter: "C", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "32", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "D", currCoord: "34", color: "#323434" },
      { id: "30", letter: "C", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "B", currCoord: "23", color: "#323434" },
      { id: "42", letter: "L", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MEDIA",
      row2: "CREED",
      row4: "ABLED",
      col0: "MECCA",
      col2: "DWELL",
      col4: "AIDED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "U", currCoord: "30", color: "#323434" },
      { id: "03", letter: "N", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "24", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "N", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "U", currCoord: "30", color: "#323434" },
      { id: "03", letter: "N", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "24", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "34", color: "#323434" },
      { id: "43", letter: "N", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "POUND",
      row2: "ROBES",
      row4: "SONNY",
      col0: "PORES",
      col2: "URBAN",
      col4: "DUSKY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "N", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "S", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "21", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "30", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "N", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "S", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "21", color: "#323434" },
      { id: "30", letter: "U", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "30", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BONDS",
      row2: "SEDAN",
      row4: "NOSES",
      col0: "BOSUN",
      col2: "NUDES",
      col4: "SINKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "W", currCoord: "20", color: "#323434" },
      { id: "03", letter: "N", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "M", currCoord: "01", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "24", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "W", currCoord: "20", color: "#323434" },
      { id: "03", letter: "N", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "M", currCoord: "01", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "24", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DAWNS",
      row2: "MOLTO",
      row4: "SIDES",
      col0: "DIMES",
      col2: "WALED",
      col4: "SHOTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "T", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "G", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "D", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "42", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "A", currCoord: "12", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "T", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "G", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "D", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "42", color: "#323434" },
      { id: "42", letter: "T", currCoord: "41", color: "#323434" },
      { id: "43", letter: "A", currCoord: "12", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NUTTY",
      row2: "GREED",
      row4: "OCTAL",
      col0: "NEGRO",
      col2: "THEFT",
      col4: "YODEL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "03", color: "#323434" },
      { id: "20", letter: "T", currCoord: "01", color: "#323434" },
      { id: "21", letter: "U", currCoord: "32", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "41", color: "#323434" },
      { id: "24", letter: "S", currCoord: "14", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "H", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "03", color: "#323434" },
      { id: "20", letter: "T", currCoord: "01", color: "#323434" },
      { id: "21", letter: "U", currCoord: "32", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "41", color: "#323434" },
      { id: "24", letter: "S", currCoord: "14", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "12", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "H", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MERSE",
      row2: "TUANS",
      row4: "OATHS",
      col0: "MOTTO",
      col2: "REACT",
      col4: "ESSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "U", currCoord: "43", color: "#323434" },
      { id: "03", letter: "B", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "10", color: "#323434" },
      { id: "30", letter: "H", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "20", color: "#323434" },
      { id: "42", letter: "R", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "U", currCoord: "43", color: "#323434" },
      { id: "03", letter: "B", currCoord: "41", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "42", color: "#323434" },
      { id: "24", letter: "L", currCoord: "10", color: "#323434" },
      { id: "30", letter: "H", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "20", color: "#323434" },
      { id: "42", letter: "R", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CLUBS",
      row2: "CABAL",
      row4: "EGRET",
      col0: "CACHE",
      col2: "UNBAR",
      col4: "SPLIT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "42", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "I", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "N", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "42", color: "#323434" },
      { id: "20", letter: "R", currCoord: "21", color: "#323434" },
      { id: "21", letter: "I", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "N", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BOOMS",
      row2: "RIDGE",
      row4: "SHEEN",
      col0: "BARNS",
      col2: "OODLE",
      col4: "SKEIN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "10", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "24", color: "#323434" },
      { id: "24", letter: "I", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "43", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "G", currCoord: "12", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "30", color: "#323434" },
      { id: "02", letter: "U", currCoord: "10", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "24", color: "#323434" },
      { id: "24", letter: "I", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "43", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "G", currCoord: "12", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CHUMS",
      row2: "RABBI",
      row4: "DIRGE",
      col0: "CORED",
      col2: "UNBAR",
      col4: "SUITE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "03", color: "#323434" },
      { id: "03", letter: "G", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "42", color: "#323434" },
      { id: "20", letter: "N", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "K", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "34", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "A", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "03", color: "#323434" },
      { id: "03", letter: "G", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "42", color: "#323434" },
      { id: "20", letter: "N", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "K", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "34", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "A", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DIRGE",
      row2: "NOOSE",
      row4: "SUTRA",
      col0: "DUNKS",
      col2: "ROOST",
      col4: "ENEMA",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "01", color: "#323434" },
      { id: "03", letter: "U", currCoord: "32", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "03", color: "#323434" },
      { id: "20", letter: "U", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "Z", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "01", color: "#323434" },
      { id: "03", letter: "U", currCoord: "32", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "03", color: "#323434" },
      { id: "20", letter: "U", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "21", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "Z", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GETUP",
      row2: "URINE",
      row4: "EASES",
      col0: "GAUZE",
      col2: "TOILS",
      col4: "PLEAS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "L", currCoord: "01", color: "#323434" },
      { id: "21", letter: "U", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "L", currCoord: "01", color: "#323434" },
      { id: "21", letter: "U", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MIRED",
      row2: "LUNAR",
      row4: "SASSY",
      col0: "MALES",
      col2: "RINKS",
      col4: "DIRTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "P", currCoord: "23", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "D", currCoord: "32", color: "#323434" },
      { id: "21", letter: "R", currCoord: "24", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "30", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "P", currCoord: "23", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "D", currCoord: "32", color: "#323434" },
      { id: "21", letter: "R", currCoord: "24", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "30", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TOPER",
      row2: "DRAIN",
      row4: "SLYLY",
      col0: "TIDES",
      col2: "PEAKY",
      col4: "RANGY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "23", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "M", currCoord: "10", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "C", currCoord: "30", color: "#323434" },
      { id: "30", letter: "B", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "12", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "A", currCoord: "24", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "23", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "M", currCoord: "10", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "C", currCoord: "30", color: "#323434" },
      { id: "30", letter: "B", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "12", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "A", currCoord: "24", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RAZED",
      row2: "MANIC",
      row4: "ASSAY",
      col0: "RUMBA",
      col2: "ZINGS",
      col4: "DECRY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "V", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "M", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "M", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "O", currCoord: "23", color: "#323434" },
      { id: "43", letter: "K", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "V", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "M", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "M", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "O", currCoord: "23", color: "#323434" },
      { id: "43", letter: "K", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LIVEN",
      row2: "MODEM",
      row4: "ROOKS",
      col0: "LAMER",
      col2: "VIDEO",
      col4: "NAMES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "N", currCoord: "34", color: "#323434" },
      { id: "03", letter: "G", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "03", color: "#323434" },
      { id: "20", letter: "R", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "14", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "N", currCoord: "34", color: "#323434" },
      { id: "03", letter: "G", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "03", color: "#323434" },
      { id: "20", letter: "R", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "02", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "14", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FANGS",
      row2: "ROMAN",
      row4: "DIRKS",
      col0: "FARAD",
      col2: "NAMER",
      col4: "SINUS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "02", color: "#323434" },
      { id: "20", letter: "B", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "01", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "I", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "41", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "02", color: "#323434" },
      { id: "20", letter: "B", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "01", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "I", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "41", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OVOID",
      row2: "BELIE",
      row4: "TIDES",
      col0: "ORBIT",
      col2: "OGLED",
      col4: "DREGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "V", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "C", currCoord: "10", color: "#323434" },
      { id: "21", letter: "H", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "21", color: "#323434" },
      { id: "30", letter: "H", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "V", currCoord: "14", color: "#323434" },
      { id: "03", letter: "E", currCoord: "20", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "C", currCoord: "10", color: "#323434" },
      { id: "21", letter: "H", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "21", color: "#323434" },
      { id: "30", letter: "H", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NEVER",
      row2: "CHAIR",
      row4: "OLDEN",
      col0: "NACHO",
      col2: "VIAND",
      col4: "RERAN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "02", letter: "K", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "R", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "W", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "02", letter: "K", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "21", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "42", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "R", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "W", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "23", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TYKES",
      row2: "THORN",
      row4: "EASES",
      col0: "TITRE",
      col2: "KNOWS",
      col4: "SONGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "43", color: "#323434" },
      { id: "02", letter: "E", currCoord: "01", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "30", color: "#323434" },
      { id: "20", letter: "E", currCoord: "12", color: "#323434" },
      { id: "21", letter: "E", currCoord: "20", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "43", color: "#323434" },
      { id: "02", letter: "E", currCoord: "01", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "30", color: "#323434" },
      { id: "20", letter: "E", currCoord: "12", color: "#323434" },
      { id: "21", letter: "E", currCoord: "20", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLEST",
      row2: "EERIE",
      row4: "DOSED",
      col0: "BREED",
      col2: "EARLS",
      col4: "TWEED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "M", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "02", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "10", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "M", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "21", color: "#323434" },
      { id: "20", letter: "B", currCoord: "02", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "10", color: "#323434" },
      { id: "24", letter: "O", currCoord: "20", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "41", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GAMES",
      row2: "BINGO",
      row4: "DIRTS",
      col0: "GIBED",
      col2: "MINER",
      col4: "SCOWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "C", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "41", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "S", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "C", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "41", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "S", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "T", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WARDS",
      row2: "OCTAL",
      row4: "HASTY",
      col0: "WOOSH",
      col2: "RATHS",
      col4: "SULLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "C", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "41", color: "#323434" },
      { id: "30", letter: "K", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "P", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "23", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "C", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "43", color: "#323434" },
      { id: "24", letter: "O", currCoord: "41", color: "#323434" },
      { id: "30", letter: "K", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "P", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LILTS",
      row2: "CANTO",
      row4: "SEEPS",
      col0: "LUCKS",
      col2: "LANCE",
      col4: "SHOWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "B", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "01", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "D", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "R", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "N", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "14", color: "#323434" },
      { id: "02", letter: "B", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "01", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "D", currCoord: "24", color: "#323434" },
      { id: "21", letter: "E", currCoord: "43", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "42", color: "#323434" },
      { id: "30", letter: "R", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "G", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "N", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HABIT",
      row2: "DELTA",
      row4: "AMENS",
      col0: "HYDRA",
      col2: "BILGE",
      col4: "TUANS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "W", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "34", color: "#323434" },
      { id: "20", letter: "N", currCoord: "01", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "32", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "21", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "W", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "34", color: "#323434" },
      { id: "20", letter: "N", currCoord: "01", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "32", color: "#323434" },
      { id: "30", letter: "T", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "T", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "21", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PROWS",
      row2: "NACHO",
      row4: "SITAR",
      col0: "PINTS",
      col2: "ONCET",
      col4: "SCOUR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "N", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "41", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "21", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "M", currCoord: "43", color: "#323434" },
      { id: "43", letter: "M", currCoord: "34", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "N", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "41", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "21", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "M", currCoord: "43", color: "#323434" },
      { id: "43", letter: "M", currCoord: "34", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "REINS",
      row2: "ANIMA",
      row4: "HOMME",
      col0: "ROACH",
      col2: "IDIOM",
      col4: "SNARE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "43", color: "#323434" },
      { id: "20", letter: "V", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "01", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "W", currCoord: "43", color: "#323434" },
      { id: "20", letter: "V", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "O", currCoord: "01", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LAMPS",
      row2: "VITAE",
      row4: "ENROL",
      col0: "LEVEE",
      col2: "MATER",
      col4: "SWELL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "10", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "S", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "10", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "20", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "S", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MUMPS",
      row2: "OUTEN",
      row4: "ESSES",
      col0: "MOOSE",
      col2: "MUTES",
      col4: "SINGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "30", color: "#323434" },
      { id: "03", letter: "S", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "41", color: "#323434" },
      { id: "21", letter: "V", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "24", color: "#323434" },
      { id: "24", letter: "D", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "30", color: "#323434" },
      { id: "03", letter: "S", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "M", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "41", color: "#323434" },
      { id: "21", letter: "V", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "24", color: "#323434" },
      { id: "24", letter: "D", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CEASE",
      row2: "OVOID",
      row4: "RARER",
      col0: "CHOIR",
      col2: "AMOUR",
      col4: "ELDER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "D", currCoord: "14", color: "#323434" },
      { id: "03", letter: "I", currCoord: "30", color: "#323434" },
      { id: "04", letter: "X", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "P", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "42", color: "#323434" },
      { id: "24", letter: "N", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "I", currCoord: "34", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "D", currCoord: "14", color: "#323434" },
      { id: "03", letter: "I", currCoord: "30", color: "#323434" },
      { id: "04", letter: "X", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "P", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "42", color: "#323434" },
      { id: "24", letter: "N", currCoord: "23", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "I", currCoord: "34", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RADIX",
      row2: "PLAIN",
      row4: "ROSIN",
      col0: "RAPER",
      col2: "DEALS",
      col4: "XENON",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Q", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "30", color: "#323434" },
      { id: "03", letter: "A", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "23", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "T", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "S", currCoord: "42", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Q", currCoord: "14", color: "#323434" },
      { id: "02", letter: "U", currCoord: "30", color: "#323434" },
      { id: "03", letter: "A", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "23", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "L", currCoord: "03", color: "#323434" },
      { id: "30", letter: "T", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "S", currCoord: "42", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SQUAT",
      row2: "IDEAL",
      row4: "SASSY",
      col0: "SUITS",
      col2: "USERS",
      col4: "TALLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "03", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "42", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "12", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "03", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "E", currCoord: "34", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "42", color: "#323434" },
      { id: "24", letter: "R", currCoord: "10", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "12", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "24", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FERRY",
      row2: "TENOR",
      row4: "DUSTS",
      col0: "FETID",
      col2: "RANTS",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "S", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "02", color: "#323434" },
      { id: "30", letter: "P", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "S", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "02", color: "#323434" },
      { id: "30", letter: "P", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "VASES",
      row2: "EYRIE",
      row4: "SUETS",
      col0: "VEEPS",
      col2: "SERVE",
      col4: "STEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "24", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "G", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "D", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "42", color: "#323434" },
      { id: "30", letter: "U", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "A", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "24", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "G", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "D", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "M", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "42", color: "#323434" },
      { id: "30", letter: "U", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "A", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MUNGY",
      row2: "DEMUR",
      row4: "SODAS",
      col0: "MODUS",
      col2: "NAMED",
      col4: "YARNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "02", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "D", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "14", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "42", color: "#323434" },
      { id: "42", letter: "H", currCoord: "30", color: "#323434" },
      { id: "43", letter: "O", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "02", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "D", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "23", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "14", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "42", color: "#323434" },
      { id: "42", letter: "H", currCoord: "30", color: "#323434" },
      { id: "43", letter: "O", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OUZEL",
      row2: "DOLOR",
      row4: "ECHOS",
      col0: "OODLE",
      col2: "ZILCH",
      col4: "LORDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "24", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "B", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "G", currCoord: "14", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "03", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "B", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "24", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "O", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "B", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "G", currCoord: "14", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "03", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "B", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ACHOO",
      row2: "AGREE",
      row4: "MAYBE",
      col0: "ALARM",
      col2: "HURRY",
      col4: "OBESE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "S", currCoord: "03", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "24", color: "#323434" },
      { id: "24", letter: "O", currCoord: "43", color: "#323434" },
      { id: "30", letter: "U", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "E", currCoord: "30", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "S", currCoord: "03", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "24", color: "#323434" },
      { id: "24", letter: "O", currCoord: "43", color: "#323434" },
      { id: "30", letter: "U", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "KEEPS",
      row2: "ASTRO",
      row4: "TOYED",
      col0: "KRAUT",
      col2: "ENTRY",
      col4: "SHOED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "S", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "32", color: "#323434" },
      { id: "30", letter: "O", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "21", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "24", color: "#323434" },
      { id: "42", letter: "B", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "S", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "20", color: "#323434" },
      { id: "20", letter: "R", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "42", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "32", color: "#323434" },
      { id: "30", letter: "O", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "21", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "24", color: "#323434" },
      { id: "42", letter: "B", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TASTE",
      row2: "RARER",
      row4: "TUBES",
      col0: "TAROT",
      col2: "SCRUB",
      col4: "EARLS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "20", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "34", color: "#323434" },
      { id: "30", letter: "T", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "30", color: "#323434" },
      { id: "43", letter: "D", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "03", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "20", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "12", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "R", currCoord: "34", color: "#323434" },
      { id: "30", letter: "T", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "42", color: "#323434" },
      { id: "42", letter: "N", currCoord: "30", color: "#323434" },
      { id: "43", letter: "D", currCoord: "23", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WORSE",
      row2: "RISER",
      row4: "HANDY",
      col0: "WORTH",
      col2: "RESIN",
      col4: "EARLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "14", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "20", color: "#323434" },
      { id: "20", letter: "S", currCoord: "12", color: "#323434" },
      { id: "21", letter: "K", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "10", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "43", color: "#323434" },
      { id: "40", letter: "C", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "02", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "14", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "20", color: "#323434" },
      { id: "20", letter: "S", currCoord: "12", color: "#323434" },
      { id: "21", letter: "K", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "10", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "43", color: "#323434" },
      { id: "40", letter: "C", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "02", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BATIK",
      row2: "SKILL",
      row4: "CELLS",
      col0: "BASIC",
      col2: "TWIRL",
      col4: "KELPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "F", currCoord: "43", color: "#323434" },
      { id: "02", letter: "I", currCoord: "12", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "14", color: "#323434" },
      { id: "21", letter: "U", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "H", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "02", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "32", color: "#323434" },
      { id: "42", letter: "A", currCoord: "03", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "F", currCoord: "43", color: "#323434" },
      { id: "02", letter: "I", currCoord: "12", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "42", color: "#323434" },
      { id: "20", letter: "O", currCoord: "14", color: "#323434" },
      { id: "21", letter: "U", currCoord: "01", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "H", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "02", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "32", color: "#323434" },
      { id: "42", letter: "A", currCoord: "03", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AFIRE",
      row2: "OUTTA",
      row4: "AVAST",
      col0: "ALOHA",
      col2: "INTRA",
      col4: "ENACT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "03", color: "#323434" },
      { id: "03", letter: "F", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "S", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "W", currCoord: "12", color: "#323434" },
      { id: "30", letter: "T", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "03", color: "#323434" },
      { id: "03", letter: "F", currCoord: "23", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "S", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "20", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "W", currCoord: "12", color: "#323434" },
      { id: "30", letter: "T", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "K", currCoord: "34", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LEAFY",
      row2: "SINEW",
      row4: "SNAKY",
      col0: "LUSTS",
      col2: "APNEA",
      col4: "YAWNY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "C", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "02", color: "#323434" },
      { id: "21", letter: "H", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "M", currCoord: "23", color: "#323434" },
      { id: "30", letter: "H", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "14", color: "#323434" },
      { id: "02", letter: "C", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "30", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "21", color: "#323434" },
      { id: "20", letter: "C", currCoord: "02", color: "#323434" },
      { id: "21", letter: "H", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "M", currCoord: "23", color: "#323434" },
      { id: "30", letter: "H", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "42", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "S", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DICED",
      row2: "CHARM",
      row4: "ANTSY",
      col0: "DACHA",
      col2: "CHANT",
      col4: "DIMLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "I", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "20", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "21", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "A", currCoord: "43", color: "#323434" },
      { id: "30", letter: "I", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "20", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TALES",
      row2: "AORTA",
      row4: "LOSES",
      col0: "TRAIL",
      col2: "LURKS",
      col4: "SPATS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "W", currCoord: "23", color: "#323434" },
      { id: "03", letter: "D", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "N", currCoord: "42", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "20", color: "#323434" },
      { id: "24", letter: "M", currCoord: "12", color: "#323434" },
      { id: "30", letter: "W", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "W", currCoord: "23", color: "#323434" },
      { id: "03", letter: "D", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "N", currCoord: "42", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "20", color: "#323434" },
      { id: "24", letter: "M", currCoord: "12", color: "#323434" },
      { id: "30", letter: "W", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "43", color: "#323434" },
      { id: "43", letter: "S", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DOWDY",
      row2: "ANNUM",
      row4: "SISSY",
      col0: "DRAWS",
      col2: "WANES",
      col4: "YUMMY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "F", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "G", currCoord: "14", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "A", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "10", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "L", currCoord: "03", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "F", currCoord: "41", color: "#323434" },
      { id: "02", letter: "T", currCoord: "12", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "G", currCoord: "14", color: "#323434" },
      { id: "21", letter: "E", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "A", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "10", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "L", currCoord: "03", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AFTER",
      row2: "GENES",
      row4: "EARLY",
      col0: "ALGAE",
      col2: "TONER",
      col4: "RUSTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "12", color: "#323434" },
      { id: "20", letter: "E", currCoord: "32", color: "#323434" },
      { id: "21", letter: "S", currCoord: "23", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "A", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "W", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "24", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "12", color: "#323434" },
      { id: "20", letter: "E", currCoord: "32", color: "#323434" },
      { id: "21", letter: "S", currCoord: "23", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "A", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "W", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BROTH",
      row2: "ESTER",
      row4: "MEOWS",
      col0: "BREAM",
      col2: "ORTHO",
      col4: "HURLS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "42", color: "#323434" },
      { id: "02", letter: "L", currCoord: "20", color: "#323434" },
      { id: "03", letter: "D", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "41", color: "#323434" },
      { id: "20", letter: "I", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "14", color: "#323434" },
      { id: "24", letter: "C", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "03", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "32", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "42", color: "#323434" },
      { id: "02", letter: "L", currCoord: "20", color: "#323434" },
      { id: "03", letter: "D", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "41", color: "#323434" },
      { id: "20", letter: "I", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "14", color: "#323434" },
      { id: "24", letter: "C", currCoord: "21", color: "#323434" },
      { id: "30", letter: "L", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "03", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "23", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "S", currCoord: "32", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GILDS",
      row2: "IONIC",
      row4: "LASSO",
      col0: "GRILL",
      col2: "LANES",
      col4: "SECCO",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "03", color: "#323434" },
      { id: "02", letter: "E", currCoord: "41", color: "#323434" },
      { id: "03", letter: "A", currCoord: "23", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "42", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "14", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "03", color: "#323434" },
      { id: "02", letter: "E", currCoord: "41", color: "#323434" },
      { id: "03", letter: "A", currCoord: "23", color: "#323434" },
      { id: "04", letter: "M", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "42", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "14", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "01", color: "#323434" },
      { id: "42", letter: "R", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BREAM",
      row2: "ROTOR",
      row4: "OGRES",
      col0: "BURRO",
      col2: "ESTER",
      col4: "MORNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "B", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "03", color: "#323434" },
      { id: "21", letter: "B", currCoord: "32", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "N", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "B", currCoord: "01", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "03", color: "#323434" },
      { id: "21", letter: "B", currCoord: "32", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "N", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CABIN",
      row2: "OBESE",
      row4: "ENSUE",
      col0: "CRONE",
      col2: "BIERS",
      col4: "NIECE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "02", color: "#323434" },
      { id: "21", letter: "L", currCoord: "03", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "30", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "12", color: "#323434" },
      { id: "43", letter: "I", currCoord: "23", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "01", color: "#323434" },
      { id: "20", letter: "A", currCoord: "02", color: "#323434" },
      { id: "21", letter: "L", currCoord: "03", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "30", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "14", color: "#323434" },
      { id: "42", letter: "T", currCoord: "12", color: "#323434" },
      { id: "43", letter: "I", currCoord: "23", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HAIRS",
      row2: "ALPHA",
      row4: "SATIN",
      col0: "HEARS",
      col2: "INPUT",
      col4: "SLAIN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "43", color: "#323434" },
      { id: "02", letter: "N", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "21", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "N", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "12", color: "#323434" },
      { id: "24", letter: "L", currCoord: "23", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "J", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "A", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "43", color: "#323434" },
      { id: "02", letter: "N", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "21", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "N", currCoord: "02", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "12", color: "#323434" },
      { id: "24", letter: "L", currCoord: "23", color: "#323434" },
      { id: "30", letter: "L", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "J", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "A", currCoord: "14", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TUNAS",
      row2: "ANNUL",
      row4: "SOARS",
      col0: "TEALS",
      col2: "NINJA",
      col4: "SILKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "A", currCoord: "32", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "M", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "30", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "14", color: "#323434" },
      { id: "03", letter: "A", currCoord: "32", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "42", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "M", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "01", color: "#323434" },
      { id: "30", letter: "C", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "30", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RENAL",
      row2: "AMIGO",
      row4: "HEELS",
      col0: "ROACH",
      col2: "NOISE",
      col4: "LOOMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "20", color: "#323434" },
      { id: "02", letter: "O", currCoord: "21", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "02", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "30", letter: "G", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "Y", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "S", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "20", color: "#323434" },
      { id: "02", letter: "O", currCoord: "21", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "12", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "02", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "32", color: "#323434" },
      { id: "30", letter: "G", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "Y", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "43", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "S", currCoord: "14", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BOOTS",
      row2: "READY",
      row4: "SASSY",
      col0: "BERGS",
      col2: "OKAYS",
      col4: "SHYLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "12", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "B", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "R", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "34", color: "#323434" },
      { id: "24", letter: "N", currCoord: "42", color: "#323434" },
      { id: "30", letter: "D", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "24", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "20", color: "#323434" },
      { id: "42", letter: "O", currCoord: "14", color: "#323434" },
      { id: "43", letter: "X", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "12", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "B", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "O", currCoord: "43", color: "#323434" },
      { id: "21", letter: "R", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "34", color: "#323434" },
      { id: "24", letter: "N", currCoord: "42", color: "#323434" },
      { id: "30", letter: "D", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "24", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "20", color: "#323434" },
      { id: "42", letter: "O", currCoord: "14", color: "#323434" },
      { id: "43", letter: "X", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ACHED",
      row2: "ORLON",
      row4: "EPOXY",
      col0: "ABODE",
      col2: "HALLO",
      col4: "DINGY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "U", currCoord: "01", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "43", color: "#323434" },
      { id: "24", letter: "T", currCoord: "21", color: "#323434" },
      { id: "30", letter: "C", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "O", currCoord: "32", color: "#323434" },
      { id: "03", letter: "A", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "U", currCoord: "01", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "43", color: "#323434" },
      { id: "24", letter: "T", currCoord: "21", color: "#323434" },
      { id: "30", letter: "C", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BROAD",
      row2: "AUDIT",
      row4: "HEEDS",
      col0: "BEACH",
      col2: "OLDIE",
      col4: "DOTES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "02", color: "#323434" },
      { id: "20", letter: "R", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "30", letter: "O", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "N", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "43", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "02", color: "#323434" },
      { id: "20", letter: "R", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "14", color: "#323434" },
      { id: "30", letter: "O", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "N", currCoord: "30", color: "#323434" },
      { id: "43", letter: "L", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GRAND",
      row2: "ROOMY",
      row4: "SONLY",
      col0: "GYROS",
      col2: "ACORN",
      col4: "DRYLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "M", currCoord: "34", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "24", color: "#323434" },
      { id: "20", letter: "V", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "20", color: "#323434" },
      { id: "02", letter: "M", currCoord: "34", color: "#323434" },
      { id: "03", letter: "P", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "24", color: "#323434" },
      { id: "20", letter: "V", currCoord: "43", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "12", color: "#323434" },
      { id: "24", letter: "A", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LAMPS",
      row2: "VILLA",
      row4: "RUSKS",
      col0: "LEVER",
      col2: "MOLLS",
      col4: "SLAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "02", color: "#323434" },
      { id: "02", letter: "A", currCoord: "30", color: "#323434" },
      { id: "03", letter: "R", currCoord: "32", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "L", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "34", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "S", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "U", currCoord: "03", color: "#323434" },
      { id: "43", letter: "N", currCoord: "24", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "02", color: "#323434" },
      { id: "02", letter: "A", currCoord: "30", color: "#323434" },
      { id: "03", letter: "R", currCoord: "32", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "21", color: "#323434" },
      { id: "21", letter: "L", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "V", currCoord: "34", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "S", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "42", color: "#323434" },
      { id: "42", letter: "U", currCoord: "03", color: "#323434" },
      { id: "43", letter: "N", currCoord: "24", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SWARF",
      row2: "ALIVE",
      row4: "HOUND",
      col0: "SMASH",
      col2: "ADIEU",
      col4: "FREED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "E", currCoord: "14", color: "#323434" },
      { id: "21", letter: "L", currCoord: "30", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "23", color: "#323434" },
      { id: "20", letter: "E", currCoord: "14", color: "#323434" },
      { id: "21", letter: "L", currCoord: "30", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "42", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "01", color: "#323434" },
      { id: "42", letter: "E", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "VEINS",
      row2: "ELATE",
      row4: "SPEED",
      col0: "VEERS",
      col2: "IRATE",
      col4: "STEAD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "M", currCoord: "24", color: "#323434" },
      { id: "03", letter: "A", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "I", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "23", color: "#323434" },
      { id: "02", letter: "M", currCoord: "24", color: "#323434" },
      { id: "03", letter: "A", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "I", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PUMAS",
      row2: "SOLAR",
      row4: "TASKS",
      col0: "POSIT",
      col2: "MELTS",
      col4: "SARIS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "N", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "23", color: "#323434" },
      { id: "30", letter: "T", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "43", color: "#323434" },
      { id: "43", letter: "O", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "O", currCoord: "01", color: "#323434" },
      { id: "03", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "C", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "N", currCoord: "30", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "23", color: "#323434" },
      { id: "30", letter: "T", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "41", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "43", color: "#323434" },
      { id: "43", letter: "O", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WOOZY",
      row2: "NOTES",
      row4: "ALTOS",
      col0: "WANTA",
      col2: "OCTET",
      col4: "YESES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "C", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "P", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "42", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "O", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "12", color: "#323434" },
      { id: "40", letter: "C", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "20", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OVOID",
      row2: "MOTEL",
      row4: "CADDY",
      col0: "OHMIC",
      col2: "OPTED",
      col4: "DALLY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "T", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "42", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "S", currCoord: "34", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "T", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "D", currCoord: "42", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TEASE",
      row2: "ANIMA",
      row4: "SUEDE",
      col0: "TEATS",
      col2: "ANISE",
      col4: "EVADE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "T", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "K", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "30", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "24", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "E", currCoord: "03", color: "#323434" },
      { id: "30", letter: "T", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "K", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CORDS",
      row2: "SOUSE",
      row4: "SEEKS",
      col0: "CYSTS",
      col2: "ROUTE",
      col4: "SEEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "T", currCoord: "34", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "T", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "14", color: "#323434" },
      { id: "24", letter: "N", currCoord: "43", color: "#323434" },
      { id: "30", letter: "R", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "20", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "T", currCoord: "34", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "T", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "14", color: "#323434" },
      { id: "24", letter: "N", currCoord: "43", color: "#323434" },
      { id: "30", letter: "R", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "20", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "E", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NEATH",
      row2: "TITAN",
      row4: "OGRES",
      col0: "NITRO",
      col2: "ALTAR",
      col4: "HINTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "41", color: "#323434" },
      { id: "20", letter: "L", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "01", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "14", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "T", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "23", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CRONE",
      row2: "LOSER",
      row4: "NATAL",
      col0: "COLON",
      col2: "ONSET",
      col4: "ENROL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "P", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "43", color: "#323434" },
      { id: "20", letter: "P", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "21", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "30", color: "#323434" },
      { id: "43", letter: "M", currCoord: "34", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "03", color: "#323434" },
      { id: "02", letter: "P", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "43", color: "#323434" },
      { id: "20", letter: "P", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "32", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "02", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "21", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "30", color: "#323434" },
      { id: "43", letter: "M", currCoord: "34", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TAPES",
      row2: "PALSY",
      row4: "RHYME",
      col0: "TOPER",
      col2: "PULPY",
      col4: "STYLE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "34", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "02", color: "#323434" },
      { id: "20", letter: "U", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "42", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "S", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "34", color: "#323434" },
      { id: "02", letter: "M", currCoord: "14", color: "#323434" },
      { id: "03", letter: "P", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "02", color: "#323434" },
      { id: "20", letter: "U", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "42", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "32", color: "#323434" },
      { id: "30", letter: "S", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "30", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "S", currCoord: "21", color: "#323434" },
      { id: "43", letter: "E", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MUMPS",
      row2: "ULTRA",
      row4: "EASES",
      col0: "MOUSE",
      col2: "MITES",
      col4: "SLAGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "V", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "21", color: "#323434" },
      { id: "24", letter: "T", currCoord: "41", color: "#323434" },
      { id: "30", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "S", currCoord: "12", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "V", currCoord: "14", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "21", color: "#323434" },
      { id: "24", letter: "T", currCoord: "41", color: "#323434" },
      { id: "30", letter: "Y", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "01", color: "#323434" },
      { id: "43", letter: "S", currCoord: "12", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SERVE",
      row2: "ADOPT",
      row4: "SASSY",
      col0: "STAYS",
      col2: "ROOFS",
      col4: "ENTRY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Z", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "21", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "X", currCoord: "24", color: "#323434" },
      { id: "42", letter: "A", currCoord: "30", color: "#323434" },
      { id: "43", letter: "M", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "34", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "T", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Z", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "N", currCoord: "41", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "21", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "X", currCoord: "24", color: "#323434" },
      { id: "42", letter: "A", currCoord: "30", color: "#323434" },
      { id: "43", letter: "M", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WALTZ",
      row2: "EATEN",
      row4: "EXAMS",
      col0: "WHERE",
      col2: "LOTTA",
      col4: "ZINGS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "S", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "20", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "32", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "K", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "W", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "N", currCoord: "43", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "S", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "20", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "32", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "K", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CHANT",
      row2: "ANOLE",
      row4: "TREKS",
      col0: "COAST",
      col2: "AWOKE",
      col4: "THEWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "12", color: "#323434" },
      { id: "20", letter: "V", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "43", color: "#323434" },
      { id: "30", letter: "O", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "C", currCoord: "21", color: "#323434" },
      { id: "43", letter: "K", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "32", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "12", color: "#323434" },
      { id: "20", letter: "V", currCoord: "01", color: "#323434" },
      { id: "21", letter: "E", currCoord: "30", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "43", color: "#323434" },
      { id: "30", letter: "O", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "23", color: "#323434" },
      { id: "42", letter: "C", currCoord: "21", color: "#323434" },
      { id: "43", letter: "K", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SPITE",
      row2: "VEINS",
      row4: "ROCKS",
      col0: "SAVOR",
      col2: "ILIAC",
      col4: "ESSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "10", color: "#323434" },
      { id: "02", letter: "E", currCoord: "20", color: "#323434" },
      { id: "03", letter: "G", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "F", currCoord: "24", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "43", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "10", color: "#323434" },
      { id: "02", letter: "E", currCoord: "20", color: "#323434" },
      { id: "03", letter: "G", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "F", currCoord: "24", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "43", color: "#323434" },
      { id: "24", letter: "L", currCoord: "32", color: "#323434" },
      { id: "30", letter: "I", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "02", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ELEGY",
      row2: "FRILL",
      row4: "NOTES",
      col0: "ELFIN",
      col2: "EVICT",
      col4: "YULES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "41", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "G", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "K", currCoord: "30", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "10", color: "#323434" },
      { id: "24", letter: "I", currCoord: "23", color: "#323434" },
      { id: "30", letter: "P", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "41", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "G", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "21", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "K", currCoord: "30", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "10", color: "#323434" },
      { id: "24", letter: "I", currCoord: "23", color: "#323434" },
      { id: "30", letter: "P", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "T", currCoord: "02", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PRIGS",
      row2: "OKAPI",
      row4: "SITAR",
      col0: "PROPS",
      col2: "INAPT",
      col4: "SKIER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "U", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "23", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "20", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "S", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "14", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "02", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "T", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "U", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "23", color: "#323434" },
      { id: "02", letter: "Z", currCoord: "10", color: "#323434" },
      { id: "03", letter: "I", currCoord: "20", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "01", color: "#323434" },
      { id: "20", letter: "S", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "14", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "32", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "02", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "T", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "UNZIP",
      row2: "SOBER",
      row4: "TESTY",
      col0: "UNSET",
      col2: "ZEBUS",
      col4: "PURTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "V", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "K", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "I", currCoord: "20", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "12", color: "#323434" },
      { id: "20", letter: "V", currCoord: "14", color: "#323434" },
      { id: "21", letter: "I", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "03", color: "#323434" },
      { id: "24", letter: "O", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "H", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "02", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "K", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "30", color: "#323434" },
      { id: "43", letter: "I", currCoord: "20", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RILES",
      row2: "VITRO",
      row4: "SKEIN",
      col0: "ROVES",
      col2: "LITHE",
      col4: "SHOWN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "03", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "O", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "F", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "03", color: "#323434" },
      { id: "20", letter: "M", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "F", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "41", color: "#323434" },
      { id: "24", letter: "A", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "10", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "U", currCoord: "30", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LOOTS",
      row2: "MAFIA",
      row4: "DONUT",
      col0: "LIMED",
      col2: "OFFEN",
      col4: "SLANT",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "12", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "23", color: "#323434" },
      { id: "20", letter: "N", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "12", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "23", color: "#323434" },
      { id: "20", letter: "N", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "02", color: "#323434" },
      { id: "30", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "21", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "43", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "T", currCoord: "03", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GERMS",
      row2: "NACHO",
      row4: "OUSTS",
      col0: "GONZO",
      col2: "RACKS",
      col4: "SHOTS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "02", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "24", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "23", color: "#323434" },
      { id: "30", letter: "U", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "20", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "A", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "02", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "12", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "24", color: "#323434" },
      { id: "20", letter: "O", currCoord: "30", color: "#323434" },
      { id: "21", letter: "Z", currCoord: "42", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "23", color: "#323434" },
      { id: "30", letter: "U", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "20", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "A", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GUSTS",
      row2: "OZONE",
      row4: "PLEAS",
      col0: "GROUP",
      col2: "SNORE",
      col4: "STEMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "03", color: "#323434" },
      { id: "02", letter: "Y", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "32", color: "#323434" },
      { id: "20", letter: "S", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "20", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "N", currCoord: "01", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "03", color: "#323434" },
      { id: "02", letter: "Y", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "32", color: "#323434" },
      { id: "20", letter: "S", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "10", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "P", currCoord: "20", color: "#323434" },
      { id: "24", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "14", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "24", color: "#323434" },
      { id: "42", letter: "N", currCoord: "01", color: "#323434" },
      { id: "43", letter: "G", currCoord: "23", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PLYER",
      row2: "SOUPY",
      row4: "RANGE",
      col0: "POSER",
      col2: "YOURN",
      col4: "RHYME",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "43", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "21", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "30", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "23", color: "#323434" },
      { id: "43", letter: "V", currCoord: "42", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "02", color: "#323434" },
      { id: "02", letter: "I", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "T", currCoord: "43", color: "#323434" },
      { id: "21", letter: "H", currCoord: "10", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "R", currCoord: "21", color: "#323434" },
      { id: "30", letter: "O", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "30", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "23", color: "#323434" },
      { id: "43", letter: "V", currCoord: "42", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ALIEN",
      row2: "THEIR",
      row4: "SALVO",
      col0: "AUTOS",
      col2: "IDEAL",
      col4: "NARCO",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "F", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "F", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "T", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "D", currCoord: "01", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "F", currCoord: "14", color: "#323434" },
      { id: "03", letter: "T", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "F", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "30", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "20", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "T", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "10", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "24", color: "#323434" },
      { id: "42", letter: "D", currCoord: "01", color: "#323434" },
      { id: "43", letter: "A", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LEFTY",
      row2: "FORMA",
      row4: "SODAS",
      col0: "LIFTS",
      col2: "FARAD",
      col4: "YEARS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "U", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "24", color: "#323434" },
      { id: "03", letter: "R", currCoord: "14", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "10", color: "#323434" },
      { id: "21", letter: "V", currCoord: "01", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "03", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "U", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "24", color: "#323434" },
      { id: "03", letter: "R", currCoord: "14", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "S", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "10", color: "#323434" },
      { id: "21", letter: "V", currCoord: "01", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "03", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "02", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ULTRA",
      row2: "EVICT",
      row4: "SMEAR",
      col0: "USERS",
      col2: "TRICE",
      col4: "ASTER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "14", color: "#323434" },
      { id: "03", letter: "A", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "F", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "G", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "10", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "O", currCoord: "42", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "14", color: "#323434" },
      { id: "03", letter: "A", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "F", currCoord: "01", color: "#323434" },
      { id: "21", letter: "L", currCoord: "03", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "12", color: "#323434" },
      { id: "24", letter: "G", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "10", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "O", currCoord: "42", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "IDEAS",
      row2: "FLING",
      row4: "ROTOR",
      col0: "INFER",
      col2: "EVICT",
      col4: "SUGAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "B", currCoord: "12", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "42", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "30", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "B", currCoord: "12", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "D", currCoord: "42", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "30", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "21", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ABIDE",
      row2: "RAGES",
      row4: "DATES",
      col0: "AIRED",
      col2: "INGOT",
      col4: "EASES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "02", color: "#323434" },
      { id: "02", letter: "B", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "T", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "M", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "14", color: "#323434" },
      { id: "43", letter: "O", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "02", color: "#323434" },
      { id: "02", letter: "B", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "T", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "34", color: "#323434" },
      { id: "24", letter: "M", currCoord: "10", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "14", color: "#323434" },
      { id: "43", letter: "O", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LOBED",
      row2: "STORM",
      row4: "DIDOS",
      col0: "LASED",
      col2: "BROAD",
      col4: "DAMNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "23", color: "#323434" },
      { id: "20", letter: "P", currCoord: "41", color: "#323434" },
      { id: "21", letter: "H", currCoord: "03", color: "#323434" },
      { id: "22", letter: "Y", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "L", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "10", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "23", color: "#323434" },
      { id: "20", letter: "P", currCoord: "41", color: "#323434" },
      { id: "21", letter: "H", currCoord: "03", color: "#323434" },
      { id: "22", letter: "Y", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "L", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "34", color: "#323434" },
      { id: "42", letter: "L", currCoord: "30", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "IDLES",
      row2: "PHYLA",
      row4: "YULES",
      col0: "IMPLY",
      col2: "LOYAL",
      col4: "SLAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "02", color: "#323434" },
      { id: "20", letter: "C", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "K", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "F", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "02", color: "#323434" },
      { id: "20", letter: "C", currCoord: "24", color: "#323434" },
      { id: "21", letter: "A", currCoord: "12", color: "#323434" },
      { id: "22", letter: "C", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "10", color: "#323434" },
      { id: "30", letter: "K", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "23", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "F", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MOIRE",
      row2: "CACHE",
      row4: "SERFS",
      col0: "MOCKS",
      col2: "INCUR",
      col4: "EVENS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "O", currCoord: "34", color: "#323434" },
      { id: "21", letter: "P", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "D", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "12", color: "#323434" },
      { id: "42", letter: "A", currCoord: "02", color: "#323434" },
      { id: "43", letter: "C", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "01", color: "#323434" },
      { id: "03", letter: "E", currCoord: "14", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "O", currCoord: "34", color: "#323434" },
      { id: "21", letter: "P", currCoord: "43", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "D", currCoord: "03", color: "#323434" },
      { id: "30", letter: "B", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "12", color: "#323434" },
      { id: "42", letter: "A", currCoord: "02", color: "#323434" },
      { id: "43", letter: "C", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BRIEF",
      row2: "OPTED",
      row4: "SPACY",
      col0: "BLOBS",
      col2: "INTRA",
      col4: "FUDGY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "32", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "N", currCoord: "34", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "41", color: "#323434" },
      { id: "24", letter: "T", currCoord: "03", color: "#323434" },
      { id: "30", letter: "N", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "01", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "14", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "32", color: "#323434" },
      { id: "02", letter: "A", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "N", currCoord: "34", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "41", color: "#323434" },
      { id: "24", letter: "T", currCoord: "03", color: "#323434" },
      { id: "30", letter: "N", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "01", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "14", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STAIR",
      row2: "INGOT",
      row4: "GAYLY",
      col0: "STING",
      col2: "ANGRY",
      col4: "RATTY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "21", color: "#323434" },
      { id: "03", letter: "S", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "03", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "01", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "W", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "21", color: "#323434" },
      { id: "03", letter: "S", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "03", color: "#323434" },
      { id: "30", letter: "R", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "U", currCoord: "01", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SWISH",
      row2: "ALARM",
      row4: "LISTS",
      col0: "SNARL",
      col2: "IKATS",
      col4: "HUMUS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "30", color: "#323434" },
      { id: "20", letter: "O", currCoord: "23", color: "#323434" },
      { id: "21", letter: "D", currCoord: "20", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "14", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "T", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "02", color: "#323434" },
      { id: "02", letter: "L", currCoord: "32", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "30", color: "#323434" },
      { id: "20", letter: "O", currCoord: "23", color: "#323434" },
      { id: "21", letter: "D", currCoord: "20", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "14", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "T", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "43", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WELCH",
      row2: "ODOUR",
      row4: "ESSAY",
      col0: "WROTE",
      col2: "LOOMS",
      col4: "HORSY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "M", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "10", color: "#323434" },
      { id: "20", letter: "G", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "03", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "21", color: "#323434" },
      { id: "02", letter: "M", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "10", color: "#323434" },
      { id: "20", letter: "G", currCoord: "42", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "03", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "O", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "12", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "34", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "R", currCoord: "01", color: "#323434" },
      { id: "44", letter: "O", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WOMEN",
      row2: "GOUDA",
      row4: "NITRO",
      col0: "WAGON",
      col2: "MOULT",
      col4: "NEATO",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "S", currCoord: "30", color: "#323434" },
      { id: "03", letter: "I", currCoord: "12", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "M", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "P", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "10", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "14", color: "#323434" },
      { id: "02", letter: "S", currCoord: "30", color: "#323434" },
      { id: "03", letter: "I", currCoord: "12", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "42", color: "#323434" },
      { id: "20", letter: "M", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "P", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "10", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "01", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RESIN",
      row2: "MAIZE",
      row4: "SEEDY",
      col0: "RUMPS",
      col2: "SLICE",
      col4: "NEEDY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "23", color: "#323434" },
      { id: "02", letter: "T", currCoord: "20", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "02", color: "#323434" },
      { id: "20", letter: "M", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "H", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "23", color: "#323434" },
      { id: "02", letter: "T", currCoord: "20", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "O", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "02", color: "#323434" },
      { id: "20", letter: "M", currCoord: "43", color: "#323434" },
      { id: "21", letter: "O", currCoord: "32", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "H", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "S", currCoord: "10", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NITRO",
      row2: "MONTH",
      row4: "RISER",
      col0: "NAMER",
      col2: "TINES",
      col4: "OTHER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "G", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "32", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "D", currCoord: "21", color: "#323434" },
      { id: "02", letter: "O", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "34", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "G", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "32", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "30", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ODORS",
      row2: "RIDGE",
      row4: "SAYER",
      col0: "OGRES",
      col2: "ODDLY",
      col4: "SPEAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "O", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "14", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "03", color: "#323434" },
      { id: "20", letter: "O", currCoord: "23", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "10", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "R", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "D", currCoord: "32", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STUMP",
      row2: "OLDER",
      row4: "TEDDY",
      col0: "SNORT",
      col2: "UNDID",
      col4: "PERKY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "23", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "S", currCoord: "02", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "I", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "42", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "W", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "30", color: "#323434" },
      { id: "02", letter: "E", currCoord: "10", color: "#323434" },
      { id: "03", letter: "A", currCoord: "23", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "V", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "S", currCoord: "02", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "12", color: "#323434" },
      { id: "24", letter: "R", currCoord: "01", color: "#323434" },
      { id: "30", letter: "I", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "42", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "W", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BREAD",
      row2: "SCOUR",
      row4: "SPEWS",
      col0: "BASIS",
      col2: "EVOKE",
      col4: "DARES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "K", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "42", color: "#323434" },
      { id: "20", letter: "F", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "F", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "20", color: "#323434" },
      { id: "42", letter: "O", currCoord: "03", color: "#323434" },
      { id: "43", letter: "P", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "M", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "23", color: "#323434" },
      { id: "03", letter: "K", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "42", color: "#323434" },
      { id: "20", letter: "F", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "24", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "F", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "20", color: "#323434" },
      { id: "42", letter: "O", currCoord: "03", color: "#323434" },
      { id: "43", letter: "P", currCoord: "30", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "MINKS",
      row2: "FORMA",
      row4: "SHOPS",
      col0: "MIFFS",
      col2: "NARCO",
      col4: "SLAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "L", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "02", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "24", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "M", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "12", color: "#323434" },
      { id: "20", letter: "L", currCoord: "23", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "A", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "02", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "T", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SEAMY",
      row2: "LASER",
      row4: "TESTS",
      col0: "SPLAT",
      col2: "ASSES",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "43", color: "#323434" },
      { id: "03", letter: "N", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "D", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "A", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "23", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "N", currCoord: "43", color: "#323434" },
      { id: "03", letter: "N", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "D", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "A", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "23", color: "#323434" },
      { id: "40", letter: "L", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NINNY",
      row2: "DODGE",
      row4: "LASED",
      col0: "NODAL",
      col2: "NUDES",
      col4: "YIELD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "D", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "21", color: "#323434" },
      { id: "20", letter: "F", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "02", color: "#323434" },
      { id: "30", letter: "F", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "30", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "D", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "21", color: "#323434" },
      { id: "20", letter: "F", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "02", color: "#323434" },
      { id: "30", letter: "F", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "30", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "10", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "41", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BIDES",
      row2: "FUDGE",
      row4: "ONSET",
      col0: "BOFFO",
      col2: "DADOS",
      col4: "SLEET",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "C", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "23", color: "#323434" },
      { id: "21", letter: "M", currCoord: "41", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "43", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "C", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "10", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "03", color: "#323434" },
      { id: "03", letter: "C", currCoord: "01", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "T", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "M", currCoord: "30", color: "#323434" },
      { id: "20", letter: "A", currCoord: "23", color: "#323434" },
      { id: "21", letter: "M", currCoord: "41", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "43", color: "#323434" },
      { id: "24", letter: "A", currCoord: "12", color: "#323434" },
      { id: "30", letter: "C", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "10", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PRICE",
      row2: "AMEBA",
      row4: "EASEL",
      col0: "PLACE",
      col2: "ITEMS",
      col4: "EMAIL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "12", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "41", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "B", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "14", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "G", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "12", color: "#323434" },
      { id: "02", letter: "R", currCoord: "10", color: "#323434" },
      { id: "03", letter: "S", currCoord: "41", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "B", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "02", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "H", currCoord: "14", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "G", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "01", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "S", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "A", currCoord: "03", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PARSE",
      row2: "ALTHO",
      row4: "ESSAY",
      col0: "PHAGE",
      col2: "RATES",
      col4: "EBONY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "K", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "K", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "21", color: "#323434" },
      { id: "42", letter: "A", currCoord: "32", color: "#323434" },
      { id: "43", letter: "I", currCoord: "24", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "K", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "01", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "A", currCoord: "43", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "03", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "K", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "21", color: "#323434" },
      { id: "42", letter: "A", currCoord: "32", color: "#323434" },
      { id: "43", letter: "I", currCoord: "24", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "JAKES",
      row2: "CARNE",
      row4: "STAIR",
      col0: "JOCKS",
      col2: "KARMA",
      col4: "SHEER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "I", currCoord: "23", color: "#323434" },
      { id: "03", letter: "S", currCoord: "30", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "M", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "03", color: "#323434" },
      { id: "42", letter: "M", currCoord: "41", color: "#323434" },
      { id: "43", letter: "P", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "20", color: "#323434" },
      { id: "02", letter: "I", currCoord: "23", color: "#323434" },
      { id: "03", letter: "S", currCoord: "30", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "M", currCoord: "42", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "01", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "32", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "03", color: "#323434" },
      { id: "42", letter: "M", currCoord: "41", color: "#323434" },
      { id: "43", letter: "P", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DEIST",
      row2: "MAIZE",
      row4: "SUMPS",
      col0: "DIMES",
      col2: "IDIOM",
      col4: "TEENS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "K", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "D", currCoord: "03", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "S", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "N", currCoord: "12", color: "#323434" },
      { id: "43", letter: "G", currCoord: "41", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "21", color: "#323434" },
      { id: "02", letter: "R", currCoord: "14", color: "#323434" },
      { id: "03", letter: "K", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "34", color: "#323434" },
      { id: "20", letter: "A", currCoord: "30", color: "#323434" },
      { id: "21", letter: "D", currCoord: "03", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "42", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "S", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "V", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "N", currCoord: "12", color: "#323434" },
      { id: "43", letter: "G", currCoord: "41", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BARKS",
      row2: "ADDLE",
      row4: "SINGE",
      col0: "BRASS",
      col2: "RADON",
      col4: "SIEVE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "24", color: "#323434" },
      { id: "03", letter: "K", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "32", color: "#323434" },
      { id: "20", letter: "P", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "12", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "24", color: "#323434" },
      { id: "03", letter: "K", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "32", color: "#323434" },
      { id: "20", letter: "P", currCoord: "10", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "E", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "W", currCoord: "12", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "01", color: "#323434" },
      { id: "42", letter: "D", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TUCKS",
      row2: "PRONE",
      row4: "DUDES",
      col0: "TOPED",
      col2: "CLOUD",
      col4: "SPEWS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "W", currCoord: "20", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "W", currCoord: "42", color: "#323434" },
      { id: "21", letter: "I", currCoord: "32", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "21", color: "#323434" },
      { id: "24", letter: "W", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "D", currCoord: "02", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "W", currCoord: "20", color: "#323434" },
      { id: "04", letter: "N", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "01", color: "#323434" },
      { id: "20", letter: "W", currCoord: "42", color: "#323434" },
      { id: "21", letter: "I", currCoord: "32", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "21", color: "#323434" },
      { id: "24", letter: "W", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "24", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "03", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "D", currCoord: "02", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DROWN",
      row2: "WIDOW",
      row4: "REEDY",
      col0: "DOWER",
      col2: "OLDIE",
      col4: "NOWAY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "S", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "41", color: "#323434" },
      { id: "20", letter: "W", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "N", currCoord: "14", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "23", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "S", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "41", color: "#323434" },
      { id: "20", letter: "W", currCoord: "32", color: "#323434" },
      { id: "21", letter: "A", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "S", currCoord: "42", color: "#323434" },
      { id: "30", letter: "A", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "20", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "N", currCoord: "14", color: "#323434" },
      { id: "43", letter: "K", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AROSE",
      row2: "WADIS",
      row4: "YANKS",
      col0: "ALWAY",
      col2: "OLDEN",
      col4: "ESSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "43", color: "#323434" },
      { id: "03", letter: "F", currCoord: "02", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "12", color: "#323434" },
      { id: "20", letter: "S", currCoord: "24", color: "#323434" },
      { id: "21", letter: "U", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "14", color: "#323434" },
      { id: "24", letter: "G", currCoord: "41", color: "#323434" },
      { id: "30", letter: "I", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "32", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "43", color: "#323434" },
      { id: "03", letter: "F", currCoord: "02", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "12", color: "#323434" },
      { id: "20", letter: "S", currCoord: "24", color: "#323434" },
      { id: "21", letter: "U", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "14", color: "#323434" },
      { id: "24", letter: "G", currCoord: "41", color: "#323434" },
      { id: "30", letter: "I", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "32", color: "#323434" },
      { id: "42", letter: "E", currCoord: "10", color: "#323434" },
      { id: "43", letter: "D", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLUFF",
      row2: "SUING",
      row4: "SEEDY",
      col0: "BASIS",
      col2: "URINE",
      col4: "FOGEY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "02", color: "#323434" },
      { id: "02", letter: "R", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "42", color: "#323434" },
      { id: "20", letter: "I", currCoord: "32", color: "#323434" },
      { id: "21", letter: "N", currCoord: "14", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "12", color: "#323434" },
      { id: "30", letter: "P", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "21", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "02", color: "#323434" },
      { id: "02", letter: "R", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "C", currCoord: "42", color: "#323434" },
      { id: "20", letter: "I", currCoord: "32", color: "#323434" },
      { id: "21", letter: "N", currCoord: "14", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "20", color: "#323434" },
      { id: "24", letter: "O", currCoord: "12", color: "#323434" },
      { id: "30", letter: "P", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "23", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "21", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "L", currCoord: "01", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FIRES",
      row2: "INTRO",
      row4: "STYLE",
      col0: "FLIPS",
      col2: "RATTY",
      col4: "SCORE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "10", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "D", currCoord: "32", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "S", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "X", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "R", currCoord: "14", color: "#323434" },
      { id: "44", letter: "A", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "30", color: "#323434" },
      { id: "02", letter: "A", currCoord: "01", color: "#323434" },
      { id: "03", letter: "C", currCoord: "10", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "12", color: "#323434" },
      { id: "21", letter: "D", currCoord: "32", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "41", color: "#323434" },
      { id: "30", letter: "S", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "F", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "03", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "X", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "23", color: "#323434" },
      { id: "43", letter: "R", currCoord: "14", color: "#323434" },
      { id: "44", letter: "A", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TRACK",
      row2: "ODOUR",
      row4: "EXTRA",
      col0: "THOSE",
      col2: "ALOFT",
      col4: "KARMA",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "23", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "02", color: "#323434" },
      { id: "20", letter: "L", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "20", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "10", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "42", color: "#323434" },
      { id: "42", letter: "L", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "30", color: "#323434" },
      { id: "02", letter: "R", currCoord: "23", color: "#323434" },
      { id: "03", letter: "E", currCoord: "32", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "02", color: "#323434" },
      { id: "20", letter: "L", currCoord: "03", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "20", color: "#323434" },
      { id: "30", letter: "L", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "10", color: "#323434" },
      { id: "40", letter: "O", currCoord: "40", color: "#323434" },
      { id: "41", letter: "G", currCoord: "42", color: "#323434" },
      { id: "42", letter: "L", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HIRER",
      row2: "LAPIS",
      row4: "OGLES",
      col0: "HELLO",
      col2: "REPEL",
      col4: "RUSKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "42", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "02", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "F", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "14", color: "#323434" },
      { id: "30", letter: "H", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "41", color: "#323434" },
      { id: "02", letter: "C", currCoord: "42", color: "#323434" },
      { id: "03", letter: "A", currCoord: "12", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "10", color: "#323434" },
      { id: "20", letter: "C", currCoord: "30", color: "#323434" },
      { id: "21", letter: "R", currCoord: "02", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "F", currCoord: "20", color: "#323434" },
      { id: "24", letter: "T", currCoord: "14", color: "#323434" },
      { id: "30", letter: "H", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "U", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "21", color: "#323434" },
      { id: "42", letter: "D", currCoord: "23", color: "#323434" },
      { id: "43", letter: "E", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DECAL",
      row2: "CROFT",
      row4: "AIDES",
      col0: "DACHA",
      col2: "CLOUD",
      col4: "LUTES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "P", currCoord: "14", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "B", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "B", currCoord: "02", color: "#323434" },
      { id: "21", letter: "R", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "L", currCoord: "24", color: "#323434" },
      { id: "43", letter: "L", currCoord: "32", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "20", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "P", currCoord: "14", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "B", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "S", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "43", color: "#323434" },
      { id: "20", letter: "B", currCoord: "02", color: "#323434" },
      { id: "21", letter: "R", currCoord: "01", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "L", currCoord: "24", color: "#323434" },
      { id: "43", letter: "L", currCoord: "32", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ERUPT",
      row2: "BRUTE",
      row4: "DALLY",
      col0: "EBBED",
      col2: "USUAL",
      col4: "TEENY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "03", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "H", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "T", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "A", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "03", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "N", currCoord: "02", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "H", currCoord: "41", color: "#323434" },
      { id: "21", letter: "I", currCoord: "24", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "T", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "10", color: "#323434" },
      { id: "43", letter: "A", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLIND",
      row2: "HILAR",
      row4: "SURAS",
      col0: "BAHTS",
      col2: "IDLER",
      col4: "DIRKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "21", color: "#323434" },
      { id: "21", letter: "V", currCoord: "43", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "D", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "P", currCoord: "23", color: "#323434" },
      { id: "02", letter: "I", currCoord: "32", color: "#323434" },
      { id: "03", letter: "K", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "20", color: "#323434" },
      { id: "20", letter: "E", currCoord: "21", color: "#323434" },
      { id: "21", letter: "V", currCoord: "43", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "14", color: "#323434" },
      { id: "30", letter: "D", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "A", currCoord: "02", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SPIKY",
      row2: "EVADE",
      row4: "STEAD",
      col0: "SEEDS",
      col2: "INANE",
      col4: "YIELD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "32", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "14", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "G", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "02", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "U", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "I", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "34", color: "#323434" },
      { id: "02", letter: "I", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "21", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "42", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "K", currCoord: "32", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "R", currCoord: "30", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "14", color: "#323434" },
      { id: "24", letter: "A", currCoord: "23", color: "#323434" },
      { id: "30", letter: "G", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "02", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "20", color: "#323434" },
      { id: "42", letter: "S", currCoord: "41", color: "#323434" },
      { id: "43", letter: "U", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "IVIES",
      row2: "AROMA",
      row4: "ENSUE",
      col0: "IMAGE",
      col2: "IDOLS",
      col4: "SKATE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "W", currCoord: "12", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "14", color: "#323434" },
      { id: "21", letter: "M", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "32", color: "#323434" },
      { id: "24", letter: "S", currCoord: "01", color: "#323434" },
      { id: "30", letter: "W", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "G", currCoord: "34", color: "#323434" },
      { id: "43", letter: "H", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "W", currCoord: "12", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "A", currCoord: "14", color: "#323434" },
      { id: "21", letter: "M", currCoord: "23", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "32", color: "#323434" },
      { id: "24", letter: "S", currCoord: "01", color: "#323434" },
      { id: "30", letter: "W", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "30", color: "#323434" },
      { id: "42", letter: "G", currCoord: "34", color: "#323434" },
      { id: "43", letter: "H", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DRAWL",
      row2: "AMISS",
      row4: "SIGHS",
      col0: "DRAWS",
      col2: "AGING",
      col4: "LOSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "03", color: "#323434" },
      { id: "02", letter: "A", currCoord: "34", color: "#323434" },
      { id: "03", letter: "R", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "41", color: "#323434" },
      { id: "20", letter: "B", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "14", color: "#323434" },
      { id: "24", letter: "W", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "03", color: "#323434" },
      { id: "02", letter: "A", currCoord: "34", color: "#323434" },
      { id: "03", letter: "R", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "B", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "41", color: "#323434" },
      { id: "20", letter: "B", currCoord: "23", color: "#323434" },
      { id: "21", letter: "E", currCoord: "12", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "14", color: "#323434" },
      { id: "24", letter: "W", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "42", color: "#323434" },
      { id: "42", letter: "D", currCoord: "20", color: "#323434" },
      { id: "43", letter: "E", currCoord: "01", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SCARY",
      row2: "BELOW",
      row4: "RIDES",
      col0: "SABER",
      col2: "ABLED",
      col4: "YAWLS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "30", color: "#323434" },
      { id: "03", letter: "K", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "P", currCoord: "02", color: "#323434" },
      { id: "21", letter: "U", currCoord: "24", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "O", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "32", color: "#323434" },
      { id: "02", letter: "L", currCoord: "30", color: "#323434" },
      { id: "03", letter: "K", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "Y", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "34", color: "#323434" },
      { id: "20", letter: "P", currCoord: "02", color: "#323434" },
      { id: "21", letter: "U", currCoord: "24", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "12", color: "#323434" },
      { id: "24", letter: "S", currCoord: "03", color: "#323434" },
      { id: "30", letter: "O", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "N", currCoord: "20", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HULKY",
      row2: "PUNTS",
      row4: "SENDS",
      col0: "HYPOS",
      col2: "LINEN",
      col4: "YESES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "S", currCoord: "42", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "30", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "W", currCoord: "43", color: "#323434" },
      { id: "24", letter: "L", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "34", color: "#323434" },
      { id: "02", letter: "S", currCoord: "01", color: "#323434" },
      { id: "03", letter: "S", currCoord: "42", color: "#323434" },
      { id: "04", letter: "A", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "L", currCoord: "30", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "W", currCoord: "43", color: "#323434" },
      { id: "24", letter: "L", currCoord: "21", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "D", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BOSSA",
      row2: "DRAWL",
      row4: "SEEDY",
      col0: "BODES",
      col2: "SHAME",
      col4: "ALLAY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "12", color: "#323434" },
      { id: "20", letter: "F", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "F", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "03", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "S", currCoord: "02", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "21", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "V", currCoord: "12", color: "#323434" },
      { id: "20", letter: "F", currCoord: "42", color: "#323434" },
      { id: "21", letter: "R", currCoord: "20", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "Z", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "32", color: "#323434" },
      { id: "30", letter: "F", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "03", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "43", color: "#323434" },
      { id: "42", letter: "T", currCoord: "01", color: "#323434" },
      { id: "43", letter: "S", currCoord: "02", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLARE",
      row2: "FROZE",
      row4: "ARTSY",
      col0: "BUFFA",
      col2: "ADOPT",
      col4: "EVERY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "S", currCoord: "42", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "S", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "24", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "U", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "B", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "14", color: "#323434" },
      { id: "03", letter: "S", currCoord: "42", color: "#323434" },
      { id: "04", letter: "K", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "12", color: "#323434" },
      { id: "20", letter: "A", currCoord: "34", color: "#323434" },
      { id: "21", letter: "S", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "24", color: "#323434" },
      { id: "24", letter: "O", currCoord: "23", color: "#323434" },
      { id: "30", letter: "U", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "B", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "43", color: "#323434" },
      { id: "42", letter: "A", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BRISK",
      row2: "ASTRO",
      row4: "SLATS",
      col0: "BEAUS",
      col2: "INTRA",
      col4: "KNOBS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "U", currCoord: "02", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "12", color: "#323434" },
      { id: "24", letter: "O", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "10", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "B", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "41", color: "#323434" },
      { id: "02", letter: "N", currCoord: "43", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "R", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "23", color: "#323434" },
      { id: "21", letter: "U", currCoord: "02", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "12", color: "#323434" },
      { id: "24", letter: "O", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "10", color: "#323434" },
      { id: "42", letter: "O", currCoord: "34", color: "#323434" },
      { id: "43", letter: "B", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BONED",
      row2: "TURBO",
      row4: "SLOBS",
      col0: "BITES",
      col2: "NARCO",
      col4: "DROSS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "01", color: "#323434" },
      { id: "03", letter: "R", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "G", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "G", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "C", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "32", color: "#323434" },
      { id: "02", letter: "I", currCoord: "01", color: "#323434" },
      { id: "03", letter: "R", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "G", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "43", color: "#323434" },
      { id: "24", letter: "G", currCoord: "20", color: "#323434" },
      { id: "30", letter: "I", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "30", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "21", color: "#323434" },
      { id: "42", letter: "C", currCoord: "41", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FAIRY",
      row2: "AGING",
      row4: "RACES",
      col0: "FLAIR",
      col2: "ILIAC",
      col4: "YOGIS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "U", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "G", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "23", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "02", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "U", currCoord: "14", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "U", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "G", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "24", color: "#323434" },
      { id: "20", letter: "T", currCoord: "32", color: "#323434" },
      { id: "21", letter: "I", currCoord: "23", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "02", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "01", color: "#323434" },
      { id: "42", letter: "S", currCoord: "30", color: "#323434" },
      { id: "43", letter: "U", currCoord: "14", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CLUNG",
      row2: "TIGER",
      row4: "ENSUE",
      col0: "CUTIE",
      col2: "URGES",
      col4: "GARDE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "34", color: "#323434" },
      { id: "02", letter: "P", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "I", currCoord: "41", color: "#323434" },
      { id: "21", letter: "N", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "03", color: "#323434" },
      { id: "24", letter: "T", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "30", color: "#323434" },
      { id: "42", letter: "N", currCoord: "02", color: "#323434" },
      { id: "43", letter: "N", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Y", currCoord: "34", color: "#323434" },
      { id: "02", letter: "P", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "23", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "I", currCoord: "41", color: "#323434" },
      { id: "21", letter: "N", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "03", color: "#323434" },
      { id: "24", letter: "T", currCoord: "32", color: "#323434" },
      { id: "30", letter: "R", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "Z", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "30", color: "#323434" },
      { id: "42", letter: "N", currCoord: "02", color: "#323434" },
      { id: "43", letter: "N", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HYPER",
      row2: "INGOT",
      row4: "SONNY",
      col0: "HAIRS",
      col2: "PAGAN",
      col4: "RITZY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "V", currCoord: "34", color: "#323434" },
      { id: "03", letter: "O", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "T", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "12", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "30", color: "#323434" },
      { id: "02", letter: "V", currCoord: "34", color: "#323434" },
      { id: "03", letter: "O", currCoord: "42", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "43", color: "#323434" },
      { id: "20", letter: "R", currCoord: "41", color: "#323434" },
      { id: "21", letter: "O", currCoord: "02", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "24", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "T", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "12", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FAVOR",
      row2: "ROILS",
      row4: "HEELS",
      col0: "FORTH",
      col2: "VOILE",
      col4: "RISKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "C", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "10", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "N", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "32", color: "#323434" },
      { id: "03", letter: "C", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "01", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "U", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "41", color: "#323434" },
      { id: "20", letter: "R", currCoord: "43", color: "#323434" },
      { id: "21", letter: "E", currCoord: "10", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "12", color: "#323434" },
      { id: "24", letter: "T", currCoord: "42", color: "#323434" },
      { id: "30", letter: "N", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "02", color: "#323434" },
      { id: "42", letter: "E", currCoord: "34", color: "#323434" },
      { id: "43", letter: "A", currCoord: "30", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BIRCH",
      row2: "RESET",
      row4: "STEAD",
      col0: "BARNS",
      col2: "RUSSE",
      col4: "HATED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "U", currCoord: "32", color: "#323434" },
      { id: "03", letter: "G", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "C", currCoord: "23", color: "#323434" },
      { id: "21", letter: "U", currCoord: "02", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "43", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "L", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "42", color: "#323434" },
      { id: "02", letter: "U", currCoord: "32", color: "#323434" },
      { id: "03", letter: "G", currCoord: "20", color: "#323434" },
      { id: "04", letter: "H", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "14", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "03", color: "#323434" },
      { id: "20", letter: "C", currCoord: "23", color: "#323434" },
      { id: "21", letter: "U", currCoord: "02", color: "#323434" },
      { id: "22", letter: "B", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "S", currCoord: "12", color: "#323434" },
      { id: "30", letter: "I", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "43", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "41", color: "#323434" },
      { id: "43", letter: "T", currCoord: "34", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "LAUGH",
      row2: "CUBES",
      row4: "DIRTS",
      col0: "LUCID",
      col2: "UNBAR",
      col4: "HUSKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "24", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "20", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "J", currCoord: "10", color: "#323434" },
      { id: "24", letter: "A", currCoord: "21", color: "#323434" },
      { id: "30", letter: "O", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "I", currCoord: "01", color: "#323434" },
      { id: "43", letter: "R", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "C", currCoord: "42", color: "#323434" },
      { id: "02", letter: "A", currCoord: "14", color: "#323434" },
      { id: "03", letter: "R", currCoord: "24", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "X", currCoord: "20", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "12", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "J", currCoord: "10", color: "#323434" },
      { id: "24", letter: "A", currCoord: "21", color: "#323434" },
      { id: "30", letter: "O", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "P", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "30", color: "#323434" },
      { id: "42", letter: "I", currCoord: "01", color: "#323434" },
      { id: "43", letter: "R", currCoord: "02", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SCARE",
      row2: "OUIJA",
      row4: "PAIRS",
      col0: "STOOP",
      col2: "ALIBI",
      col4: "EXAMS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "P", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "P", currCoord: "03", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "X", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "L", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "P", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "42", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Y", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "10", color: "#323434" },
      { id: "20", letter: "P", currCoord: "03", color: "#323434" },
      { id: "21", letter: "I", currCoord: "02", color: "#323434" },
      { id: "22", letter: "X", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "14", color: "#323434" },
      { id: "24", letter: "L", currCoord: "30", color: "#323434" },
      { id: "30", letter: "E", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "23", color: "#323434" },
      { id: "42", letter: "E", currCoord: "43", color: "#323434" },
      { id: "43", letter: "D", currCoord: "41", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "RAPID",
      row2: "PIXEL",
      row4: "SHEDS",
      col0: "ROPES",
      col2: "PYXIE",
      col4: "DALES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "D", currCoord: "03", color: "#323434" },
      { id: "43", letter: "D", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "23", color: "#323434" },
      { id: "02", letter: "R", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "43", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "12", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "14", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "10", color: "#323434" },
      { id: "21", letter: "L", currCoord: "34", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "41", color: "#323434" },
      { id: "24", letter: "N", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "I", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "20", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "A", currCoord: "02", color: "#323434" },
      { id: "42", letter: "D", currCoord: "03", color: "#323434" },
      { id: "43", letter: "D", currCoord: "21", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BERET",
      row2: "OLDEN",
      row4: "DADDY",
      col0: "BLOND",
      col2: "REDID",
      col4: "TINNY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "42", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "G", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "H", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "23", color: "#323434" },
      { id: "43", letter: "S", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "R", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "A", currCoord: "42", color: "#323434" },
      { id: "03", letter: "C", currCoord: "24", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "41", color: "#323434" },
      { id: "20", letter: "G", currCoord: "14", color: "#323434" },
      { id: "21", letter: "O", currCoord: "12", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "02", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "H", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "03", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "M", currCoord: "43", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "23", color: "#323434" },
      { id: "43", letter: "S", currCoord: "10", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "REACT",
      row2: "GOOSE",
      row4: "THESE",
      col0: "RIGHT",
      col2: "ANODE",
      col4: "THEME",
    },
  },
  {
    waffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "F", currCoord: "21", color: "#323434" },
      { id: "03", letter: "F", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "R", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "A", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "30", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "O", currCoord: "34", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "D", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "24", color: "#323434" },
      { id: "02", letter: "F", currCoord: "21", color: "#323434" },
      { id: "03", letter: "F", currCoord: "32", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "P", currCoord: "42", color: "#323434" },
      { id: "20", letter: "E", currCoord: "01", color: "#323434" },
      { id: "21", letter: "R", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "D", currCoord: "14", color: "#323434" },
      { id: "24", letter: "E", currCoord: "20", color: "#323434" },
      { id: "30", letter: "A", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "23", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "30", color: "#323434" },
      { id: "42", letter: "T", currCoord: "10", color: "#323434" },
      { id: "43", letter: "O", currCoord: "34", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "DOFFS",
      row2: "ERODE",
      row4: "ROTOR",
      col0: "DREAR",
      col2: "FLOAT",
      col4: "SPEAR",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Q", currCoord: "21", color: "#323434" },
      { id: "02", letter: "U", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "B", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "M", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "01", color: "#323434" },
      { id: "24", letter: "O", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "N", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "Q", currCoord: "21", color: "#323434" },
      { id: "02", letter: "U", currCoord: "41", color: "#323434" },
      { id: "03", letter: "I", currCoord: "24", color: "#323434" },
      { id: "04", letter: "B", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "10", color: "#323434" },
      { id: "21", letter: "M", currCoord: "42", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "01", color: "#323434" },
      { id: "24", letter: "O", currCoord: "32", color: "#323434" },
      { id: "30", letter: "N", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "O", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "12", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "34", color: "#323434" },
      { id: "42", letter: "N", currCoord: "43", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SQUIB",
      row2: "AMINO",
      row4: "TENET",
      col0: "SCANT",
      col2: "UNION",
      col4: "BOOST",
    },
  },
  {
    waffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "41", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "C", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "12", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "N", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "J", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "32", color: "#323434" },
      { id: "02", letter: "T", currCoord: "01", color: "#323434" },
      { id: "03", letter: "T", currCoord: "24", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "21", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "03", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "D", currCoord: "41", color: "#323434" },
      { id: "22", letter: "L", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "23", color: "#323434" },
      { id: "30", letter: "C", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "12", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "V", currCoord: "43", color: "#323434" },
      { id: "42", letter: "E", currCoord: "14", color: "#323434" },
      { id: "43", letter: "N", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "JETTY",
      row2: "IDLER",
      row4: "EVENS",
      col0: "JUICE",
      col2: "TILDE",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "L", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "20", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "H", currCoord: "01", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "42", color: "#323434" },
      { id: "24", letter: "K", currCoord: "10", color: "#323434" },
      { id: "30", letter: "L", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "30", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "T", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "21", color: "#323434" },
      { id: "02", letter: "L", currCoord: "24", color: "#323434" },
      { id: "03", letter: "E", currCoord: "41", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "02", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "20", color: "#323434" },
      { id: "20", letter: "S", currCoord: "03", color: "#323434" },
      { id: "21", letter: "H", currCoord: "01", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "42", color: "#323434" },
      { id: "24", letter: "K", currCoord: "10", color: "#323434" },
      { id: "30", letter: "L", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "S", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "30", color: "#323434" },
      { id: "40", letter: "A", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "32", color: "#323434" },
      { id: "42", letter: "T", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "12", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "TILED",
      row2: "SHANK",
      row4: "ANTES",
      col0: "TESLA",
      col2: "LEAST",
      col4: "DIKES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "C", currCoord: "43", color: "#323434" },
      { id: "03", letter: "K", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "I", currCoord: "03", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "24", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "T", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "C", currCoord: "43", color: "#323434" },
      { id: "03", letter: "K", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "21", color: "#323434" },
      { id: "20", letter: "D", currCoord: "23", color: "#323434" },
      { id: "21", letter: "I", currCoord: "03", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "24", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "12", color: "#323434" },
      { id: "42", letter: "S", currCoord: "34", color: "#323434" },
      { id: "43", letter: "T", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "COCKS",
      row2: "DIRGE",
      row4: "DUSTS",
      col0: "CODED",
      col2: "CORNS",
      col4: "SEEPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "E", currCoord: "03", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "12", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "C", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "10", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "14", color: "#323434" },
      { id: "42", letter: "A", currCoord: "24", color: "#323434" },
      { id: "43", letter: "N", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "E", currCoord: "03", color: "#323434" },
      { id: "03", letter: "D", currCoord: "01", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "T", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "O", currCoord: "42", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "12", color: "#323434" },
      { id: "24", letter: "N", currCoord: "02", color: "#323434" },
      { id: "30", letter: "C", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "K", currCoord: "10", color: "#323434" },
      { id: "40", letter: "K", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "14", color: "#323434" },
      { id: "42", letter: "A", currCoord: "24", color: "#323434" },
      { id: "43", letter: "N", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SEEDY",
      row2: "OCEAN",
      row4: "KHANS",
      col0: "STOCK",
      col2: "ENEMA",
      col4: "YANKS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "I", currCoord: "01", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "A", currCoord: "10", color: "#323434" },
      { id: "03", letter: "N", currCoord: "21", color: "#323434" },
      { id: "04", letter: "D", currCoord: "04", color: "#323434" },
      { id: "10", letter: "O", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "24", color: "#323434" },
      { id: "20", letter: "I", currCoord: "01", color: "#323434" },
      { id: "21", letter: "C", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "N", currCoord: "02", color: "#323434" },
      { id: "24", letter: "S", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "20", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "B", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "43", color: "#323434" },
      { id: "40", letter: "G", currCoord: "40", color: "#323434" },
      { id: "41", letter: "R", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "14", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GLAND",
      row2: "ICONS",
      row4: "GREED",
      col0: "GOING",
      col2: "ADOBE",
      col4: "DOSED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "L", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "34", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "D", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "K", currCoord: "00", color: "#323434" },
      { id: "01", letter: "N", currCoord: "21", color: "#323434" },
      { id: "02", letter: "E", currCoord: "43", color: "#323434" },
      { id: "03", letter: "L", currCoord: "41", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "30", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "20", color: "#323434" },
      { id: "20", letter: "T", currCoord: "34", color: "#323434" },
      { id: "21", letter: "H", currCoord: "02", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "03", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "E", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "M", currCoord: "10", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "F", currCoord: "23", color: "#323434" },
      { id: "40", letter: "D", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "A", currCoord: "01", color: "#323434" },
      { id: "43", letter: "D", currCoord: "24", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "KNELT",
      row2: "THEIR",
      row4: "DEADS",
      col0: "KITED",
      col2: "EDEMA",
      col4: "TURFS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "20", color: "#323434" },
      { id: "03", letter: "T", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "03", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "R", currCoord: "23", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "N", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "I", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "12", color: "#323434" },
      { id: "02", letter: "O", currCoord: "20", color: "#323434" },
      { id: "03", letter: "T", currCoord: "10", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "K", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "03", color: "#323434" },
      { id: "20", letter: "I", currCoord: "02", color: "#323434" },
      { id: "21", letter: "R", currCoord: "23", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "32", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "N", currCoord: "34", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "P", currCoord: "24", color: "#323434" },
      { id: "42", letter: "I", currCoord: "14", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SLOTS",
      row2: "IRATE",
      row4: "SPIED",
      col0: "SPINS",
      col2: "OKAPI",
      col4: "STEAD",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "42", color: "#323434" },
      { id: "03", letter: "F", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "D", currCoord: "01", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "D", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "M", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "02", color: "#323434" },
      { id: "02", letter: "E", currCoord: "42", color: "#323434" },
      { id: "03", letter: "F", currCoord: "14", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "D", currCoord: "01", color: "#323434" },
      { id: "21", letter: "R", currCoord: "03", color: "#323434" },
      { id: "22", letter: "U", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "20", color: "#323434" },
      { id: "24", letter: "D", currCoord: "34", color: "#323434" },
      { id: "30", letter: "E", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "C", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "L", currCoord: "10", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "H", currCoord: "12", color: "#323434" },
      { id: "42", letter: "E", currCoord: "41", color: "#323434" },
      { id: "43", letter: "M", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CHEFS",
      row2: "DRUID",
      row4: "THEME",
      col0: "CADET",
      col2: "EDUCE",
      col4: "SIDLE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "V", currCoord: "02", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "21", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "B", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "H", currCoord: "10", color: "#323434" },
      { id: "30", letter: "V", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "30", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "L", currCoord: "34", color: "#323434" },
      { id: "03", letter: "V", currCoord: "02", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "21", color: "#323434" },
      { id: "20", letter: "A", currCoord: "24", color: "#323434" },
      { id: "21", letter: "B", currCoord: "03", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "S", currCoord: "12", color: "#323434" },
      { id: "24", letter: "H", currCoord: "10", color: "#323434" },
      { id: "30", letter: "V", currCoord: "42", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "P", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "O", currCoord: "30", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "14", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "E", currCoord: "20", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HALVE",
      row2: "ABASH",
      row4: "YESES",
      col0: "HEAVY",
      col2: "LEAPS",
      col4: "ETHOS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "34", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "C", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "U", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "I", currCoord: "23", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "O", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "34", color: "#323434" },
      { id: "02", letter: "H", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "24", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "C", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "12", color: "#323434" },
      { id: "20", letter: "C", currCoord: "02", color: "#323434" },
      { id: "21", letter: "A", currCoord: "14", color: "#323434" },
      { id: "22", letter: "K", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "43", color: "#323434" },
      { id: "24", letter: "S", currCoord: "41", color: "#323434" },
      { id: "30", letter: "U", currCoord: "01", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "R", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "32", color: "#323434" },
      { id: "43", letter: "I", currCoord: "23", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "OTHER",
      row2: "CAKES",
      row4: "ROSIN",
      col0: "OCCUR",
      col2: "HIKES",
      col4: "RISEN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "41", color: "#323434" },
      { id: "02", letter: "V", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "P", currCoord: "32", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "10", color: "#323434" },
      { id: "30", letter: "P", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "D", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "C", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "41", color: "#323434" },
      { id: "02", letter: "V", currCoord: "12", color: "#323434" },
      { id: "03", letter: "I", currCoord: "34", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "03", color: "#323434" },
      { id: "21", letter: "P", currCoord: "32", color: "#323434" },
      { id: "22", letter: "R", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "30", color: "#323434" },
      { id: "24", letter: "N", currCoord: "10", color: "#323434" },
      { id: "30", letter: "P", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "V", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "G", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "D", currCoord: "21", color: "#323434" },
      { id: "44", letter: "E", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "CIVIL",
      row2: "APRON",
      row4: "SUEDE",
      col0: "CHAPS",
      col2: "VERVE",
      col4: "LUNGE",
    },
  },
  {
    waffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "L", currCoord: "23", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "12", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "D", currCoord: "14", color: "#323434" },
      { id: "30", letter: "U", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "V", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "43", color: "#323434" },
      { id: "02", letter: "U", currCoord: "42", color: "#323434" },
      { id: "03", letter: "L", currCoord: "23", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "R", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "12", color: "#323434" },
      { id: "20", letter: "R", currCoord: "10", color: "#323434" },
      { id: "21", letter: "I", currCoord: "01", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "02", color: "#323434" },
      { id: "24", letter: "D", currCoord: "14", color: "#323434" },
      { id: "30", letter: "U", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "41", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "20", color: "#323434" },
      { id: "43", letter: "S", currCoord: "30", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "VAULT",
      row2: "RIGID",
      row4: "SISSY",
      col0: "VIRUS",
      col2: "URGES",
      col4: "TEDDY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "P", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "X", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Z", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "M", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "L", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "14", color: "#323434" },
      { id: "44", letter: "H", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "E", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "43", color: "#323434" },
      { id: "03", letter: "P", currCoord: "10", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "X", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "Z", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "M", currCoord: "41", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "U", currCoord: "30", color: "#323434" },
      { id: "24", letter: "R", currCoord: "02", color: "#323434" },
      { id: "30", letter: "L", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "01", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "42", color: "#323434" },
      { id: "40", letter: "T", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "20", color: "#323434" },
      { id: "42", letter: "E", currCoord: "03", color: "#323434" },
      { id: "43", letter: "T", currCoord: "14", color: "#323434" },
      { id: "44", letter: "H", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "ELOPE",
      row2: "AMOUR",
      row4: "TEETH",
      col0: "EXALT",
      col2: "OZONE",
      col4: "EARTH",
    },
  },
  {
    waffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "L", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "N", currCoord: "01", color: "#323434" },
      { id: "30", letter: "M", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "12", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "H", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "02", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "03", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "L", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "20", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "24", color: "#323434" },
      { id: "24", letter: "N", currCoord: "01", color: "#323434" },
      { id: "30", letter: "M", currCoord: "43", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "21", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "L", currCoord: "12", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "23", color: "#323434" },
      { id: "43", letter: "L", currCoord: "10", color: "#323434" },
      { id: "44", letter: "Y", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "HATES",
      row2: "LADEN",
      row4: "SLYLY",
      col0: "HELMS",
      col2: "TEDDY",
      col4: "SANDY",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "D", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "U", currCoord: "34", color: "#323434" },
      { id: "21", letter: "N", currCoord: "30", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "10", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "I", currCoord: "12", color: "#323434" },
      { id: "02", letter: "D", currCoord: "41", color: "#323434" },
      { id: "03", letter: "D", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "23", color: "#323434" },
      { id: "20", letter: "U", currCoord: "34", color: "#323434" },
      { id: "21", letter: "N", currCoord: "30", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "10", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "E", currCoord: "03", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "32", color: "#323434" },
      { id: "42", letter: "R", currCoord: "02", color: "#323434" },
      { id: "43", letter: "T", currCoord: "21", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BIDDY",
      row2: "UNTIL",
      row4: "SORTS",
      col0: "BLUES",
      col2: "DOTER",
      col4: "YULES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "32", color: "#323434" },
      { id: "20", letter: "T", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "C", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "D", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "21", color: "#323434" },
      { id: "02", letter: "T", currCoord: "41", color: "#323434" },
      { id: "03", letter: "L", currCoord: "02", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "42", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "32", color: "#323434" },
      { id: "20", letter: "T", currCoord: "14", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "P", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "34", color: "#323434" },
      { id: "24", letter: "R", currCoord: "30", color: "#323434" },
      { id: "30", letter: "C", currCoord: "24", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "43", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "N", currCoord: "03", color: "#323434" },
      { id: "40", letter: "H", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "23", color: "#323434" },
      { id: "42", letter: "D", currCoord: "12", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WETLY",
      row2: "TAPER",
      row4: "HIDES",
      col0: "WATCH",
      col2: "TAPED",
      col4: "YARNS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "30", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "34", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "D", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "U", currCoord: "20", color: "#323434" },
      { id: "02", letter: "N", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "42", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "23", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "30", color: "#323434" },
      { id: "20", letter: "R", currCoord: "32", color: "#323434" },
      { id: "21", letter: "O", currCoord: "34", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "21", color: "#323434" },
      { id: "24", letter: "R", currCoord: "43", color: "#323434" },
      { id: "30", letter: "E", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "D", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "14", color: "#323434" },
      { id: "42", letter: "D", currCoord: "01", color: "#323434" },
      { id: "43", letter: "E", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FUNNY",
      row2: "ROTOR",
      row4: "SIDES",
      col0: "FIRES",
      col2: "NOTED",
      col4: "YARDS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "02", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "E", currCoord: "14", color: "#323434" },
      { id: "21", letter: "N", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "N", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "A", currCoord: "00", color: "#323434" },
      { id: "01", letter: "V", currCoord: "43", color: "#323434" },
      { id: "02", letter: "A", currCoord: "32", color: "#323434" },
      { id: "03", letter: "I", currCoord: "02", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "M", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "24", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "U", currCoord: "30", color: "#323434" },
      { id: "20", letter: "E", currCoord: "14", color: "#323434" },
      { id: "21", letter: "N", currCoord: "41", color: "#323434" },
      { id: "22", letter: "T", currCoord: "22", color: "#323434" },
      { id: "23", letter: "E", currCoord: "01", color: "#323434" },
      { id: "24", letter: "R", currCoord: "12", color: "#323434" },
      { id: "30", letter: "N", currCoord: "23", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "20", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "42", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "10", color: "#323434" },
      { id: "42", letter: "R", currCoord: "34", color: "#323434" },
      { id: "43", letter: "E", currCoord: "21", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "AVAIL",
      row2: "ENTER",
      row4: "SIRED",
      col0: "AMENS",
      col2: "ALTAR",
      col4: "LURED",
    },
  },
  {
    waffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "T", currCoord: "14", color: "#323434" },
      { id: "03", letter: "U", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "43", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "W", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "A", currCoord: "10", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "F", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "34", color: "#323434" },
      { id: "02", letter: "T", currCoord: "14", color: "#323434" },
      { id: "03", letter: "U", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "24", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "E", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "43", color: "#323434" },
      { id: "20", letter: "O", currCoord: "32", color: "#323434" },
      { id: "21", letter: "U", currCoord: "23", color: "#323434" },
      { id: "22", letter: "N", currCoord: "22", color: "#323434" },
      { id: "23", letter: "C", currCoord: "20", color: "#323434" },
      { id: "24", letter: "E", currCoord: "01", color: "#323434" },
      { id: "30", letter: "W", currCoord: "21", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "41", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "S", currCoord: "02", color: "#323434" },
      { id: "43", letter: "A", currCoord: "10", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "FETUS",
      row2: "OUNCE",
      row4: "SISAL",
      col0: "FLOWS",
      col2: "TENDS",
      col4: "STEAL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "I", currCoord: "32", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "O", currCoord: "23", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "T", currCoord: "20", color: "#323434" },
      { id: "02", letter: "R", currCoord: "21", color: "#323434" },
      { id: "03", letter: "I", currCoord: "32", color: "#323434" },
      { id: "04", letter: "P", currCoord: "04", color: "#323434" },
      { id: "10", letter: "N", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "O", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "43", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "R", currCoord: "42", color: "#323434" },
      { id: "22", letter: "G", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "10", color: "#323434" },
      { id: "24", letter: "N", currCoord: "30", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "34", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "14", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "N", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "03", color: "#323434" },
      { id: "43", letter: "O", currCoord: "23", color: "#323434" },
      { id: "44", letter: "L", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "STRIP",
      row2: "ARGON",
      row4: "ENROL",
      col0: "SNARE",
      col2: "ROGER",
      col4: "PANEL",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "32", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "M", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "M", currCoord: "03", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "10", color: "#323434" },
      { id: "24", letter: "A", currCoord: "34", color: "#323434" },
      { id: "30", letter: "U", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "32", color: "#323434" },
      { id: "02", letter: "A", currCoord: "43", color: "#323434" },
      { id: "03", letter: "M", currCoord: "42", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "E", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "G", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "N", currCoord: "02", color: "#323434" },
      { id: "20", letter: "A", currCoord: "01", color: "#323434" },
      { id: "21", letter: "M", currCoord: "03", color: "#323434" },
      { id: "22", letter: "E", currCoord: "22", color: "#323434" },
      { id: "23", letter: "B", currCoord: "10", color: "#323434" },
      { id: "24", letter: "A", currCoord: "34", color: "#323434" },
      { id: "30", letter: "U", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "N", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "12", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "20", color: "#323434" },
      { id: "42", letter: "T", currCoord: "24", color: "#323434" },
      { id: "43", letter: "E", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BEAMS",
      row2: "AMEBA",
      row4: "SITES",
      col0: "BEAUS",
      col2: "AGENT",
      col4: "SNAPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "O", currCoord: "30", color: "#323434" },
      { id: "03", letter: "N", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "02", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "12", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "24", color: "#323434" },
      { id: "24", letter: "W", currCoord: "20", color: "#323434" },
      { id: "30", letter: "T", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "03", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "O", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "O", currCoord: "30", color: "#323434" },
      { id: "03", letter: "N", currCoord: "14", color: "#323434" },
      { id: "04", letter: "Y", currCoord: "04", color: "#323434" },
      { id: "10", letter: "I", currCoord: "21", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "D", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "02", color: "#323434" },
      { id: "20", letter: "E", currCoord: "42", color: "#323434" },
      { id: "21", letter: "N", currCoord: "12", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "O", currCoord: "24", color: "#323434" },
      { id: "24", letter: "W", currCoord: "20", color: "#323434" },
      { id: "30", letter: "T", currCoord: "10", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "L", currCoord: "23", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "P", currCoord: "03", color: "#323434" },
      { id: "40", letter: "Y", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "01", color: "#323434" },
      { id: "42", letter: "Y", currCoord: "41", color: "#323434" },
      { id: "43", letter: "O", currCoord: "32", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "PEONY",
      row2: "ENDOW",
      row4: "YOYOS",
      col0: "PIETY",
      col2: "ODDLY",
      col4: "YAWPS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "24", color: "#323434" },
      { id: "03", letter: "P", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "41", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "42", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "M", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "N", currCoord: "03", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "H", currCoord: "34", color: "#323434" },
      { id: "02", letter: "O", currCoord: "24", color: "#323434" },
      { id: "03", letter: "P", currCoord: "43", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "23", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "X", currCoord: "01", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "T", currCoord: "10", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "41", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "42", color: "#323434" },
      { id: "24", letter: "A", currCoord: "14", color: "#323434" },
      { id: "30", letter: "M", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "D", currCoord: "30", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "I", currCoord: "20", color: "#323434" },
      { id: "40", letter: "E", currCoord: "40", color: "#323434" },
      { id: "41", letter: "M", currCoord: "21", color: "#323434" },
      { id: "42", letter: "E", currCoord: "02", color: "#323434" },
      { id: "43", letter: "N", currCoord: "03", color: "#323434" },
      { id: "44", letter: "D", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SHOPS",
      row2: "ANIMA",
      row4: "EMEND",
      col0: "SHAME",
      col2: "OXIDE",
      col4: "STAID",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "W", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "23", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "P", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "N", currCoord: "14", color: "#323434" },
      { id: "43", letter: "A", currCoord: "01", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "12", color: "#323434" },
      { id: "02", letter: "W", currCoord: "03", color: "#323434" },
      { id: "03", letter: "N", currCoord: "20", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "41", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "I", currCoord: "34", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "H", currCoord: "23", color: "#323434" },
      { id: "20", letter: "L", currCoord: "02", color: "#323434" },
      { id: "21", letter: "O", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "G", currCoord: "10", color: "#323434" },
      { id: "24", letter: "E", currCoord: "30", color: "#323434" },
      { id: "30", letter: "P", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "24", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "O", currCoord: "21", color: "#323434" },
      { id: "42", letter: "N", currCoord: "14", color: "#323434" },
      { id: "43", letter: "A", currCoord: "01", color: "#323434" },
      { id: "44", letter: "R", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GOWNS",
      row2: "LODGE",
      row4: "SONAR",
      col0: "GULPS",
      col2: "WIDEN",
      col4: "SHEER",
    },
  },
  {
    waffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "I", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "14", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "C", currCoord: "41", color: "#323434" },
      { id: "43", letter: "K", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "G", currCoord: "00", color: "#323434" },
      { id: "01", letter: "R", currCoord: "10", color: "#323434" },
      { id: "02", letter: "I", currCoord: "34", color: "#323434" },
      { id: "03", letter: "E", currCoord: "01", color: "#323434" },
      { id: "04", letter: "F", currCoord: "04", color: "#323434" },
      { id: "10", letter: "R", currCoord: "20", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "L", currCoord: "43", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "23", color: "#323434" },
      { id: "20", letter: "A", currCoord: "12", color: "#323434" },
      { id: "21", letter: "N", currCoord: "24", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "M", currCoord: "21", color: "#323434" },
      { id: "24", letter: "A", currCoord: "30", color: "#323434" },
      { id: "30", letter: "I", currCoord: "32", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "02", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "T", currCoord: "14", color: "#323434" },
      { id: "40", letter: "N", currCoord: "40", color: "#323434" },
      { id: "41", letter: "I", currCoord: "03", color: "#323434" },
      { id: "42", letter: "C", currCoord: "41", color: "#323434" },
      { id: "43", letter: "K", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "GRIEF",
      row2: "ANIMA",
      row4: "NICKS",
      col0: "GRAIN",
      col2: "ILIAC",
      col4: "FIATS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "01", color: "#323434" },
      { id: "20", letter: "E", currCoord: "43", color: "#323434" },
      { id: "21", letter: "D", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "T", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "S", currCoord: "00", color: "#323434" },
      { id: "01", letter: "A", currCoord: "24", color: "#323434" },
      { id: "02", letter: "U", currCoord: "12", color: "#323434" },
      { id: "03", letter: "T", currCoord: "30", color: "#323434" },
      { id: "04", letter: "E", currCoord: "04", color: "#323434" },
      { id: "10", letter: "P", currCoord: "03", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "N", currCoord: "20", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "S", currCoord: "01", color: "#323434" },
      { id: "20", letter: "E", currCoord: "43", color: "#323434" },
      { id: "21", letter: "D", currCoord: "34", color: "#323434" },
      { id: "22", letter: "I", currCoord: "22", color: "#323434" },
      { id: "23", letter: "T", currCoord: "41", color: "#323434" },
      { id: "24", letter: "S", currCoord: "10", color: "#323434" },
      { id: "30", letter: "R", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "T", currCoord: "14", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "E", currCoord: "21", color: "#323434" },
      { id: "40", letter: "M", currCoord: "40", color: "#323434" },
      { id: "41", letter: "U", currCoord: "32", color: "#323434" },
      { id: "42", letter: "S", currCoord: "23", color: "#323434" },
      { id: "43", letter: "T", currCoord: "42", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "SAUTE",
      row2: "EDITS",
      row4: "MUSTS",
      col0: "SPERM",
      col2: "UNITS",
      col4: "ESSES",
    },
  },
  {
    waffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "K", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "03", color: "#323434" },
      { id: "42", letter: "I", currCoord: "21", color: "#323434" },
      { id: "43", letter: "O", currCoord: "10", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "P", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "43", color: "#323434" },
      { id: "02", letter: "K", currCoord: "30", color: "#323434" },
      { id: "03", letter: "E", currCoord: "34", color: "#323434" },
      { id: "04", letter: "R", currCoord: "04", color: "#323434" },
      { id: "10", letter: "L", currCoord: "02", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "32", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "O", currCoord: "20", color: "#323434" },
      { id: "20", letter: "A", currCoord: "42", color: "#323434" },
      { id: "21", letter: "L", currCoord: "41", color: "#323434" },
      { id: "22", letter: "A", currCoord: "22", color: "#323434" },
      { id: "23", letter: "R", currCoord: "14", color: "#323434" },
      { id: "24", letter: "M", currCoord: "23", color: "#323434" },
      { id: "30", letter: "N", currCoord: "12", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "K", currCoord: "24", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "A", currCoord: "01", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "C", currCoord: "03", color: "#323434" },
      { id: "42", letter: "I", currCoord: "21", color: "#323434" },
      { id: "43", letter: "O", currCoord: "10", color: "#323434" },
      { id: "44", letter: "N", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "POKER",
      row2: "ALARM",
      row4: "SCION",
      col0: "PLANS",
      col2: "KHAKI",
      col4: "ROMAN",
    },
  },
  {
    waffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "W", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "M", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "23", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "P", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "N", currCoord: "00", color: "#323434" },
      { id: "01", letter: "E", currCoord: "43", color: "#323434" },
      { id: "02", letter: "W", currCoord: "03", color: "#323434" },
      { id: "03", letter: "E", currCoord: "42", color: "#323434" },
      { id: "04", letter: "L", currCoord: "04", color: "#323434" },
      { id: "10", letter: "A", currCoord: "34", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "H", currCoord: "41", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "I", currCoord: "32", color: "#323434" },
      { id: "20", letter: "M", currCoord: "12", color: "#323434" },
      { id: "21", letter: "O", currCoord: "23", color: "#323434" },
      { id: "22", letter: "O", currCoord: "22", color: "#323434" },
      { id: "23", letter: "L", currCoord: "02", color: "#323434" },
      { id: "24", letter: "A", currCoord: "01", color: "#323434" },
      { id: "30", letter: "E", currCoord: "14", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "R", currCoord: "21", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "R", currCoord: "20", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "30", color: "#323434" },
      { id: "42", letter: "E", currCoord: "24", color: "#323434" },
      { id: "43", letter: "P", currCoord: "10", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "NEWEL",
      row2: "MOOLA",
      row4: "SEEPS",
      col0: "NAMES",
      col2: "WHORE",
      col4: "LIARS",
    },
  },
  {
    waffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "20", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "21", color: "#323434" },
      { id: "21", letter: "L", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "P", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "U", currCoord: "14", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "W", currCoord: "00", color: "#323434" },
      { id: "01", letter: "O", currCoord: "42", color: "#323434" },
      { id: "02", letter: "R", currCoord: "20", color: "#323434" },
      { id: "03", letter: "M", currCoord: "02", color: "#323434" },
      { id: "04", letter: "S", currCoord: "04", color: "#323434" },
      { id: "10", letter: "H", currCoord: "32", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "10", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "E", currCoord: "01", color: "#323434" },
      { id: "20", letter: "O", currCoord: "21", color: "#323434" },
      { id: "21", letter: "L", currCoord: "43", color: "#323434" },
      { id: "22", letter: "D", currCoord: "22", color: "#323434" },
      { id: "23", letter: "I", currCoord: "30", color: "#323434" },
      { id: "24", letter: "E", currCoord: "34", color: "#323434" },
      { id: "30", letter: "P", currCoord: "41", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "A", currCoord: "12", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "S", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "T", currCoord: "24", color: "#323434" },
      { id: "42", letter: "R", currCoord: "23", color: "#323434" },
      { id: "43", letter: "U", currCoord: "14", color: "#323434" },
      { id: "44", letter: "T", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "WORMS",
      row2: "OLDIE",
      row4: "STRUT",
      col0: "WHOPS",
      col2: "RADAR",
      col4: "SEEST",
    },
  },
  {
    waffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "32", color: "#323434" },
      { id: "02", letter: "E", currCoord: "20", color: "#323434" },
      { id: "03", letter: "A", currCoord: "21", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "N", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "12", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "K", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWaffle: [
      { id: "00", letter: "B", currCoord: "00", color: "#323434" },
      { id: "01", letter: "L", currCoord: "32", color: "#323434" },
      { id: "02", letter: "E", currCoord: "20", color: "#323434" },
      { id: "03", letter: "A", currCoord: "21", color: "#323434" },
      { id: "04", letter: "T", currCoord: "04", color: "#323434" },
      { id: "10", letter: "U", currCoord: "43", color: "#323434" },
      { id: "11", letter: " ", currCoord: "11", color: "#1a1a1b" },
      { id: "12", letter: "A", currCoord: "30", color: "#323434" },
      { id: "13", letter: " ", currCoord: "13", color: "#1a1a1b" },
      { id: "14", letter: "A", currCoord: "34", color: "#323434" },
      { id: "20", letter: "N", currCoord: "41", color: "#323434" },
      { id: "21", letter: "A", currCoord: "01", color: "#323434" },
      { id: "22", letter: "S", currCoord: "22", color: "#323434" },
      { id: "23", letter: "A", currCoord: "12", color: "#323434" },
      { id: "24", letter: "L", currCoord: "14", color: "#323434" },
      { id: "30", letter: "K", currCoord: "02", color: "#323434" },
      { id: "31", letter: " ", currCoord: "31", color: "#1a1a1b" },
      { id: "32", letter: "E", currCoord: "42", color: "#323434" },
      { id: "33", letter: " ", currCoord: "33", color: "#1a1a1b" },
      { id: "34", letter: "C", currCoord: "03", color: "#323434" },
      { id: "40", letter: "S", currCoord: "40", color: "#323434" },
      { id: "41", letter: "E", currCoord: "24", color: "#323434" },
      { id: "42", letter: "L", currCoord: "10", color: "#323434" },
      { id: "43", letter: "L", currCoord: "23", color: "#323434" },
      { id: "44", letter: "S", currCoord: "44", color: "#323434" },
    ],
    solutionWords: {
      row0: "BLEAT",
      row2: "NASAL",
      row4: "SELLS",
      col0: "BUNKS",
      col2: "EASEL",
      col4: "TALCS",
    },
  },
];
